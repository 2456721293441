import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import columns from "./hireRecordsHeaders";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';


const HireRecords = () =>{
    const [ hireData, setHireData ] = useState()
    const [ display, setDisplay ] = useState()

    useEffect(() =>{
        UserService.getHireRecords().then(
            response =>{
                setHireData(response.data)
                console.log(response.data)
            }
        ).catch(err => console.log(err.message))
    },[])

    useEffect(() =>{
        let data = []
        hireData?.map(row =>{
            data.push({
                id: row.entry,
                account_no: row.account_no,
                name: row.name,
                reg: row.reg,
                hire_start: row.hire_start,
                hire_end: row.hire_end,
                status: row.status,
                notes: row.notes
            })
        })
        console.log(data)
        setDisplay(data)
    }, [hireData])

    const handleSearch = (e) =>{
        let data = []
        hireData?.map(row => {
            if(row.name?.toLowerCase().match(e.target.value?.toLowerCase()) || row.reg?.toLowerCase().match(e.target.value?.toLowerCase())){
                data.push({
                    id: row.entry,
                    account_no: row.account_no,
                    name: row.name,
                    reg: row.reg,
                    hire_start: row.hire_start,
                    hire_end: row.hire_end,
                    status: row.status,
                    notes: row.notes
                })
            }
        })
        setDisplay(data)
    }
    
    return(
        <div style={{marginLeft: '50px'}}>
            <div className='flex'>
                <input
                    type="search"
                    placeholder="Search here"
                    //onChange={handleChange}
                    //value={searchInput}
                    id='searchInput4'
                    className='searchInput' 
                    onChange={e => handleSearch(e)}
                />
            </div>
            <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
                }}}>
                    <DataGrid
                    
                        //onRowSelectionModelChange={params => setSelected(params)}
                        rows={display ? display : ''}
                        columns={columns}
                        initialState={{
                            pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                            },
                        }}
                        sx={{fontSize: '16px', fontFamily: ''}}
                        pageSizeOptions={[100]}
                        //onRowClick={params => handleRow(params)}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        //loading={loading}
                        {...display}
                    />
            </Box>
        </div>
    )
}
export default HireRecords;