import React, { Component, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CircularProgress from '@mui/material/CircularProgress';

const steps = [
    'Select a date',
    'Complete the van details',
    'Complete payment details',
    'Select Suplier',
    'Select insurance'
  ];
let applicantsOptions = [];

const typeOptions = [
    {value: 'PCN', label: 'PCN'},
    {value: 'FPN', label: 'FPN'},
];

let assetsOptions = [];
const codesOptions =[];
const authorityOptions = [];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

const  AddPcn = () => {
    const [ date, setDate ] = useState()
    const [ pcnNo, setPcnNo ] = useState()
    const [ reg, setReg ] = useState()
    const [ name, setName]  = useState()
    const [ type, setType ] = useState()
    const [ cost, setCost ] = useState()
    const [ assets, setAssets] = useState()
    const [ models, setModels] = useState()
    const [ checkSubmit, setCheckSubmit] = useState()
    const [ applicants, setApplicants] = useState()
    const [ codes, setCodes] = useState()
    const [ code, setCode] = useState()
    const [ issuing, setIssuing] = useState()
    const [ selectedOption, setSelectedOption] = useState()
    const [ selectedFile, setSelectedFile] = useState(null)
    const [ fileName, setFileName] = useState()
    const [ description, setDescription] = useState()
    const [ authority, setAuthority] = useState()
    const [ uploaded, setUploaded] = useState(false)
    const [ load, setLoad] = useState(false)
    const [ siNo, setSiNo ] = useState()
      
    
    useEffect(() =>{    
        UserService.getOffRoadAssets().then(
            response => {
              setAssets(response.data)
                let assets = response.data;
                let assetsLen = assets.length;
                for(let i = 0; i< assetsLen; i++){
                    assetsOptions.push({value: assets[i].reg, label: assets[i].reg})
                }
            },
            error => {
              setAssets(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
                  )
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getAllApplicants().then(
            response => {
              setApplicants(response.data)
              let applicants = response.data;
                let applicantsLen = applicants.length;
                for(let i =0; i < applicantsLen; i++){
                    applicantsOptions.push({value: applicants[i].name, label: applicants[i].name})
                }
            },
            error => {
              setApplicants(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
                  )
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getCodes().then(
            response => {
              setCodes(response.data)
              let codes = response.data;
              let codesLen = codes.length;
              for(let i =0; i < codesLen; i++){
                  codesOptions.push({value: codes[i].Code, label: codes[i].Code})
              }
            },
            error => {
              setCodes(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
                  )
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getIssuing().then(
            response => {
              setIssuing(response.data)
              let issuing = response.data;
                let isssuingLen = issuing.length;
                for(let i =0; i < isssuingLen; i++){
                    authorityOptions.push({value: issuing[i].Council, label: issuing[i].Council})
                }
            },
            error => {
              setIssuing(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
                  )
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );    
    }, [])
    
        const handlePcnNo = (e) =>{
            setPcnNo(e.target.value)
        }

        const handleReg = (e) =>{
            setReg(e.value)
        }

        const handleName = (e) =>{
            setName(e.value)
        }

        const handleType = (e) =>{
            setType(e.value)
        }
        const handleCode = (e) =>{
            setCode(e.value)
            codes?.map(row =>{
                    if(row.Code == e.value){
                        setDescription(row.Description)
                    }
                }
            )
        }

        const handleSDate = (date) =>{
            setDate(date)
        }
     
        const handleAuth = (e) =>{
            setAuthority(e.value)
        }

        const handleDescription = (e) =>{
            setDescription(e.target.value)
        }
        const handleCost= (e) =>{
            setCost(e.target.value)
          
        }
        const handleOptionChange = (e) =>{
            setSelectedOption(e.target.value)
        }
        
        let dataSend ={};
        const handleSubmit = () =>{
            
            dataSend = {PCN_No: pcnNo, supplier_invoice_no: siNo,  Vehicle_Reg: reg, Driver: name, PCN_Type: type, Cost: cost, PCN_Date: date, PCN_Code: code, PCN_Description: description, Issued_By: authority, Status: selectedOption, File: 'http://localhost:8080/public/uploads/'+fileName+'.pdf'}
            console.log(dataSend);
            UserService.insertPCN(dataSend)
            .then(response => {
                setCheckSubmit(true) 
                console.log(response)
            }).catch(err => {
                setCheckSubmit(false)
                console.log(err)
            })
            onFileUpload();
        }
        const onFileChange = (event) => {
            // Update the state
            setSelectedFile(event.target.files[0])
        };
        const onFileUpload = () => {
            setLoad(true)
            setFileName(new Date().getTime())
            const formData = new FormData();
            formData.append(
                "myFile",
                selectedFile,
                fileName
            );
            console.log(selectedFile);
            axios.post("https://node-skso.co.uk/uploadFile", formData)
                    .then(response => {
                        //this.setState({checkSubmit: true})
                        setLoad(false)
                        setUploaded(true)
                        console.log(response)})
                    .catch(err =>{
                        console.log(err)
                        //this.setState({checkSubmit: false})
                    })    
        };
        const fileData = () => {
            if (selectedFile) {
                
                return (
                    <div>
                        <h2>File Details:</h2>
                        <p>
                            File Name:{" "}
                            {fileName}
                        </p>
     
                        <p>
                            File Type:{" "}
                            {selectedFile.type}
                        </p>
     
                        <p>
                            Last Modified:{" "}
                            {selectedFile.lastModifiedDate.toDateString()}
                        </p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <br />
                        <h4>
                            Choose before Pressing the Upload
                            button
                        </h4>
                    </div>
                );
            }
        }
        return(
            <>
            {checkSubmit == true ? <h1 className="assetsOutcome">PCN Added!!</h1> : checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
            <div>
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Add PCN</h1>
                    </header> 
                    
                    <div>
                        
                        <h2 className="subttV"></h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='pcnNo' className="eCorrection correctionFieldsMobile5">PCN No <span className="req">(Require)</span></label>
                                <input type="text" name='pcnNo' className="twoIn2 correctionFieldsMobile5" onChange={e => handlePcnNo(e)} value={pcnNo?.toUpperCase()} />
                            </div>
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection correctionFieldsMobile5">Vehicle Registration <span className="req">(Require)</span></label>
                                <Select 
                                    options={assetsOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleReg(e)}
                                    className="correctionFieldsMobile4"
                                /> 
                            </div>
                        </div>  
                        <div className="inlineLnx">
                            <label for='class' className="eCorrection correctionFieldsMobile6">Name <span className="req">(Require)</span></label>
                                <Select 
                                    options={applicantsOptions}
                                    styles={colourStylesS}
                                    onChange={e => setName(e.value)}
                                    className="correctionFieldsMobile3"
                                /> 
                        </div>
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Supplier Invoice No<span className="req">(Require)</span></label>
                              <input type="text" name='notes' className="twoIn2 correctionFieldsMobile" onChange={e => setSiNo(e.target.value)} /*defaultValue={model}*/  />
                        </div>
                        <h2 className="subttV">Contravention</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='type' className="eCorrection correctionFieldsMobile5">Type <span className="req">(Require)</span></label>
                                <Select 
                                    options={typeOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleType(e)}
                                    className="correctionFieldsMobile4"
                                /> 
                            </div>
                            <div className="inlineLnI">
                                <label for='currentCost' className="eCorrection correctionFieldsMobile5">Current Cost <span className="req">(Require)</span></label>
                                <input type="number" name='currentCost' className="twoIn2 correctionFieldsMobile5" value={cost} onChange={e => handleCost(e)}  />
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='d' className="eCorrection correctionFieldsMobile5">Date <span className="req">(Require)</span></label>
                                {/*<DatePicker selected={date} onChange={(date) => handleSDate(date)} className="correctionFieldsMobile3"/>*/}
                                <Calendar  
                                    onChange={e => handleSDate(e)} 
                                    //value={value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    className={'calendarX'}
                                />
                            </div>
                            
                            <div className="inlineLnI">
                                <label for='mileage' className="eCorrection correctionFieldsMobile5">Code <span className="req">(Require)</span></label>
                                <Select 
                                    options={codesOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleCode(e)}
                                    className="correctionFieldsMobile4"
                                /> 
                            </div>
                        </div>
                        <div className="inlineLnx">
                                <label for='mileage' className="eCorrection correctionFieldsMobile6">Description <span className="req">(Require)</span></label>
                                <input type="textarea" name='mileage' className="twoIn2 texta correctionFieldsMobile6"  value={description} onChange={e => handleDescription(e)}  />
                            </div>
                        <h2 className="subttV">Issuing Authority</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnx">
                                <label for='rate' className="eCorrection">Authority <span className="req">(Require)</span></label>
                                <Select 
                                    options={authorityOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleAuth(e)}
                                    
                                /> 
                            </div>
                          
                        </div>
                        {/*<h2 className="subttV">Status</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='supplier' className="eCorrection">Outcome of PCN <span className="req">(Require)</span></label>
                                <form>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appeal Successful - Authority Cancelled" 
                                                    checked={selectedOption === 'Appeal Successful - Authority Cancelled'} 
                                                    onChange={handleOptionChange} />
                                            <> Appeal Successful - Authority Cancelled</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appeal Successful - Redirected to Driver" 
                                                    checked={selectedOption === 'Appeal Successful - Redirected to Driver'} 
                                                    onChange={handleOptionChange} />
                                         <> Appeal Successful - Redirected to Driver</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appealed - Direct to Authority" 
                                                    checked={selectedOption === 'Appealed - Direct to Authority'} 
                                                    onChange={handleOptionChange} />
                                        <> Appealed - Direct to Authority</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appealed - Redirected to Driver" 
                                                    checked={selectedOption === 'Appealed - Redirected to Driver'} 
                                                    onChange={handleOptionChange} />
                                        <> Appealed - Redirected to Driver</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Paid - No Recharge" 
                                                    checked={selectedOption === 'Paid - No Recharge'} 
                                                    onChange={handleOptionChange} />
                                        <> Paid - No Recharge</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Paid - Recharged to Driver" 
                                                    checked={selectedOption === 'Paid - Recharged to Driver'} 
                                                    onChange={handleOptionChange} />
                                        <> Paid - Recharged to Driver</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Waiting on Authority Notice" 
                                                    checked={selectedOption === 'Waiting on Authority Notice'} 
                                                    onChange={handleOptionChange} />
                                        <> Waiting on Authority Notice</>
                                    </label>
                                    </div>
                                </form>
                            </div>
                           
                        </div>*/}
                        <h2 className="subttV">Upload</h2>
                        <div className="flexAssets">
                            <div className="inlineLnx">
                                <label for='rate' className="eCorrection">Attach PCN <span className="req">(Require)</span></label>
                                <div className="attach">
                                    
                                    <div>
                                        <input
                                            type="file"
                                            onChange={onFileChange}
                                        />
                                        {uploaded == false ?
                                            <button className="button" onClick={onFileUpload}>
                                               Upload!
                                            </button>
                                            : <CheckRoundedIcon/>
                                        }
                                    </div>
                                    {fileData}
                                </div>
                                
                            </div>
                          
                        </div>
                        <div className="submitButtonAss">
                            <button type="submit" className="submitButtonnAss" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div> 
                </div>
            </div> 
     
            } 
            </>                   
        )
    
}
export default AddPcn;