const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 20 },
    {
      field: 'account_no',
      headerName: 'Account No',
      headerClassName: 'table-header',
      width: 220,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'table-header',
      width: 220,
      editable: false,
    },
   {
      field: 'reg',
      headerName: 'Reg',
      headerClassName: 'table-header',
      width: 220,
      editable: false,
    },
    {
       field: 'hire_start',
       headerName: 'Hire Start',
       headerClassName: 'table-header',
       width: 220,
       type: 'date',
       editable: false,
       valueFormatter: params => 
        new Date(params?.value).toLocaleDateString('en-GB')
     },
     {
        field: 'hire_end',
        headerName: 'Hire End',
        headerClassName: 'table-header',
        width: 220,
        type: 'date',
       editable: false,
       valueFormatter: params => 
        new Date(params?.value).toLocaleDateString('en-GB')
      },
      {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 220,
        editable: false,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 220,
        editable: false,
      }
]
export default columns;