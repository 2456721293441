import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import Video from './video.component';
import useFetch from './fetch.component';
import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AuthService from '../../services/auth.service';
import userService from '../../services/user.service';


const VideoList = () => {
 const user = AuthService.getCurrentUser();
 const [searchInput, setSearchInput] = useState("");
 const [ oVideo, setOVideo ] = useState(false)
 const [ vParams, setVParams ] = useState("")
 let url = '/videoDataToTable';
 let data =  useFetch(url);
 
 console.log(data);
 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      headerClassName: 'table-header',
      width: 220,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'table-header',
      width: 180,
      editable: false,
    },
   {
      field: 'vehicle_vin',
      headerName: 'Vin number',
      headerClassName: 'table-header',
      width: 240,
      editable: false,
    },
    {
       field: 'vehicle_reg',
       headerName: 'Reg',
       headerClassName: 'table-header',
       width: 180,
       editable: false,
     },
     {
        field: 'vehicle_make',
        headerName: 'Vehicle Make',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
      },
      {
         field: 'vehicle_model',
         headerName: 'Vehicle model',
         headerClassName: 'table-header',
         width: 180,
         editable: false,
       },
       {
        field: 'damages_windscreen',
        headerName: 'Windscreen',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'lights_condition',
        headerName: 'Lights',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'damages_bodywork',
        headerName: 'Bodywork',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'damages_sharp',
        headerName: 'Sharp/Unsafe Defects',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'tyre_condition',
        headerName: 'Tyres',
        headerClassName: 'table-header',
        width: 230,
        editable: false,
      },
      {
        field: 'overall_condition',
        headerName: 'Overall Condition',
        headerClassName: 'table-header',
        width: 270,
        editable: false,
      },
       {
          field: 'driver_upload',
          headerName: 'Created By',
          headerClassName: 'table-header',
          width: 180,
          editable: false,
        },
        {
           field: 'date_upload',
           headerName: 'Upload Date',
           headerClassName: 'table-header',
           width: 180,
           editable: false,
         },
         {
            field: 'video_uplink',
            headerName: 'Video',
            headerClassName: 'table-header',
            width: 180,
            //editable: true,
            type: 'component',
            renderCell: (params) => (
                <div>{/*<Video params={params} />*/}<button className='buttonVid' onClick={e => openVideo(e, params)}>Open video</button></div>
                
              ),
           
          },
          {
            field: 'notes',
            headerName: 'Notes',
            headerClassName: 'table-header',
            width: 380,
            editable: true
          },
          {
             field: 'action',
             headerName: 'Action',
             headerClassName: 'table-header',
             width: 180,
             editable: true,
             type: 'singleSelect',
             valueOptions: ['Action Required', 'No Action Required'],
             /*valueGetter: (params) => {
              if (!params.value) {
                return params.value;
              }
              console.log(params)
              handleOC(params.value, params.row.id)
              return params.value
            },*/
           },
           {
              field: 'action_by',
              headerName: 'Action by',
              headerClassName: 'table-header',
              width: 180,
              editable: true,
              type: 'singleSelect',
              valueOptions: [user.username],//['Filmer', 'Dan', 'Garth', 'Ewelina', 'Ventsi', 'Nick', 'Ben', 'Maddison', 'George'],
              /*valueGetter: (params) => {
                if (!params.value) {
                  return params.value;
                }
              
                handleBy(params.value, params.row.id)
                return params.value
              },*/
            }
  ];
  function handleBy(value, id){
    let dataH = {};
    let dataCheck = dataH;
    dataH = {option: value, id: id}
    //console.log(dataH)
    if(dataCheck == dataH){ 
      console.log('same dataH')
    }else{
      fetch('https://node-skso.co.uk/updateBy', {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin":"*",
          "Accept": "application/json",
          "Content-Type": "application/json"
          },
        body: JSON.stringify(dataH),
      }).then((response) => response.json())
        .then((dataH) => {
                //console.log(dataH);
        }).catch(err =>console.log(err));
      }
      data?.map(dataRow => {
        if(dataRow.primary == id){
          dataRow.action_by = value;
        }  
       });
  }
  function handleOC(value, id){
    let dataH = {};
    let dataCheck = dataH;
    dataH = {option: value, id: id}
    //console.log(dataH)
    if(dataCheck == dataH){ 
      console.log('same data')
    }else{
      fetch('https://node-skso.co.uk/updateVideo', {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin":"*",
          "Accept": "application/json",
          "Content-Type": "application/json"
          },
        body: JSON.stringify(dataH),
      }).then((response) => response.json())
        .then((dataH) => {
                //console.log(dataH);
        }).catch(err =>console.log(err));
      }
     data?.map(dataRow => {
      if(dataRow.primary == id){
        dataRow.action = value;
      }  
     });
  }
 


let displaiC = [];
data?.reverse()
if(data){
  console.log(data);
data?.map(dataRow => {
    
    displaiC.push(
        {id: dataRow.primary, timestamp: dataRow.timestamp.split('T')[0].split('-')[2]+'-'+dataRow.timestamp.split('T')[0].split('-')[1]+'-'+dataRow.timestamp.split('T')[0].split('-')[0]+' - '+dataRow.timestamp.split('T')[1].split('.')[0], status: dataRow.status , vehicle_vin: dataRow.vehicle_vin, vehicle_reg: dataRow.vehicle_reg, vehicle_make: dataRow.vehicle_make, vehicle_model: dataRow.vehicle_model, damages_windscreen: dataRow.damages_windscreen == 'Yes' ? 'Ok' : dataRow.damages_windscreen == 'No'? 'Damages Reported' : '', lights_condition: dataRow.lights_condition == 'Yes' ? 'Ok' : dataRow.lights_condition == 'No' ? 'Damages Reported' : '', damages_bodywork: dataRow.damages_bodywork == 'Yes' ? 'Ok' : dataRow.damages_bodywork == 'No' ? 'Damages Reported' : '', damages_sharp: dataRow.damages_sharp == 'Yes' ? 'Ok' : dataRow.damages_sharp == 'No' ? 'Damages Reported' : '', tyre_condition: dataRow.tyre_condition == 'Yes' ? 'Ok' : dataRow.tyre_condition == 'No' ? 'Tyre(s) Bold/Damage' : '', overall_condition: dataRow.overall_condition == 'Yes' ? 'Ok' : dataRow.overall_condition == 'No' ? 'Require Mecanic/Bodyshop' : '', driver_upload: dataRow.driver_upload, date_upload: dataRow.date_upload?.split('-')[0].toString().padStart(2, "0")+'-'+dataRow.date_upload?.split('-')[1].toString().padStart(2, "0")+'-'+dataRow.date_upload?.split('-')[2], video_uplink: dataRow.video_uplink, action: dataRow.action, action_by: dataRow.action_by, notes: dataRow.notes }
    )   

    })
  }

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(e.target.value);
};
//console.log(searchInput);
let findC = [];
if (searchInput.length > 0) {
    data.filter(dataRow => {
        if(dataRow.vehicle_reg.toUpperCase().match(searchInput.toUpperCase())){
            console.log(findC)
            findC.push(
              {id: dataRow.primary, timestamp: dataRow.timestamp.split('T')[0].split('-')[2]+'-'+dataRow.timestamp.split('T')[0].split('-')[1]+'-'+dataRow.timestamp.split('T')[0].split('-')[0]+' - '+dataRow.timestamp.split('T')[1].split('.')[0], status: dataRow.status , vehicle_vin: dataRow.vehicle_vin, vehicle_reg: dataRow.vehicle_reg, vehicle_make: dataRow.vehicle_make, vehicle_model: dataRow.vehicle_model, damages_windscreen: dataRow.damages_windscreen == 'Yes' ? 'Ok' : dataRow.damages_windscreen == 'No'? 'Damages Reported' : '', lights_condition: dataRow.lights_condition == 'Yes' ? 'Ok' : dataRow.lights_condition == 'No' ? 'Damages Reported' : '', damages_bodywork: dataRow.damages_bodywork == 'Yes' ? 'Ok' : dataRow.damages_bodywork == 'No' ? 'Damages Reported' : '', damages_sharp: dataRow.damages_sharp == 'Yes' ? 'Ok' : dataRow.damages_sharp == 'No' ? 'Damages Reported' : '', tyre_condition: dataRow.tyre_condition == 'Yes' ? 'Ok' : dataRow.tyre_condition == 'No' ? 'Tyre(s) Bold/Damage' : '', overall_condition: dataRow.overall_condition == 'Yes' ? 'Ok' : dataRow.overall_condition == 'No' ? 'Require Mecanic/Bodyshop' : '', driver_upload: dataRow.driver_upload, date_upload: dataRow.date_upload, video_uplink: dataRow.video_uplink, action: dataRow.action, action_by: dataRow.action_by, notes: dataRow.notes }
            )
        }
      
});

}
const closeVideo = () =>{
  setVParams("")
  setOVideo(false)
}
const openVideo = (e, params) =>{
  setVParams(params)
  setOVideo(true)
}

const handleParams = (params) =>{
  console.log(params)
  let data = {id:params.id, notes: params.notes, action: params.action, action_by: params.action_by}
  userService.updateVideoLog(data).then(
    response =>{
      console.log(response)
    }
  ).catch(err =>console.log(err.message))
  return params
}


return( 
<div>
<input
   type="search"
   placeholder="Search here"
   onChange={handleChange}
   value={searchInput}
   className='searchInput' 
   />


{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 1300, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
      bgcolor: (theme) =>
        theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    }}}>
        <DataGrid
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
        sx={{fontSize: 'larger'}}
       slots={{
        loadingOverlay: LinearProgress,
      }}
      //loading
        //{...data}
        processRowUpdate={handleParams}
        onProcessRowUpdateError={err => console.log(err)}
      
        />
    </Box> : <Box sx={{height: 1300, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        }}}>
        <DataGrid
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        
        pageSizeOptions={[15]}
          sx={{fontSize: 'larger'}}
        //{...data}
        processRowUpdate={handleParams}
        onProcessRowUpdateError={err => console.log(err)}
        />
    </Box>}
    <React.Fragment>
      <Dialog
        fullScreen
        open={oVideo}
        //TransitionComponent={Transition}
        keepMounted
        //onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{}</DialogTitle>
        <DialogContent>
          <Video params={vParams} />
        </DialogContent>
        <DialogActions>
          <button className="buttonSS" onClick={closeVideo}>Close</button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
</div>
)

};

export default VideoList;