import React, { Component } from "react";

import UserService from "../services/user.service";

export default class HireTc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    const myP1 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"8.0pt",
      marginLeft:"0cm",
      fontSize:"11.0pt",
      fontFamily:"'Calibri',sans-serif"
    }
    const myP2 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"8.0pt",
      marginLeft:"36.0pt",
      fontSize:"11.0pt",
      fontFamily:'"Calibri",sans-serif',
      textIndent:"36.0pt"
    }
    const myP3 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"8.0pt",
      marginLeft:"0cm",
      fontSize:"11.0pt",
      fontFamily:'"Calibri",sans-serif',
      textIndent:"36.0pt"
    }
    const myP4 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"8.0pt",
      marginLeft:"0cm",
      fontSize:"11.0pt",
      fontFamily:'"Calibri",sans-serif',
      textIndent:"36.0pt"
    }
    return (
      <div className="containerf containerfCorrection mobileContainerCorrection">
        <p style={myP1}>Table of Contents</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}>1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;The Company, it&rsquo;s service and definitions.</p>
        <p style={myP1}>2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;The Companies Obligations.</p>
        <p style={myP1}>3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;The Contract.</p>
        <p style={myP1}>4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;To whom these T&amp;C&rsquo;s Apply to.</p>
        <p style={myP1}>5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Who can We Rent To?</p>
        <p style={myP1}>6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Where can you drive our Vehicle?</p>
        <p style={myP1}>7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Clients Obligations Towards the Vehicle.</p>
        <p style={myP1}>8.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Other Charges that may apply.</p>
        <p style={myP1}>9.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;When collecting a Vehicle.</p>
        <p style={myP1}>10.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;When Returning a Vehicle.</p>
        <p style={myP1}>11.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Damage to the Vehicle.</p>
        <p style={myP1}>12.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Client obligations toward the vehicle.</p>
        <p style={myP1}>13.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Mechanical Breakdowns, Accidents and Theft.</p>
        <p style={myP1}>14.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Cancellations.</p>
        <p style={myP1}>15.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Agreement Extensions.</p>
        <p style={myP1}>16.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Vehicle Telematics.</p>
        <p style={myP1}>17.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Protecting your data.</p>
        <p style={myP1}>18.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Disputes and Complaints Procedure.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>1. The Company, it&rsquo;s Service and Definitions.</strong></p>
        <p style={myP1}>1.1. Skilled Solutions Ltd is a Private Limited Company registered in England and Wales under Company Number 10819845. Our registered office is at 20 Nobel Square, Basildon, Essex SS13 1LP.</p>
        <p style={myP1}>1.2. Skilled Solutions provides Hire Services for vehicles up to 3.5 tonne commercially for Hire and Reward and fleet management services.</p>
        <p style={myP1}>1.3. For this document, Skilled Solutions Ltd will from here on be referred to as &ldquo;The Company&rdquo; or &ldquo;The Supplier&rdquo;.</p>
        <p style={myP1}>1.4. For the purposes of this document, the Hirer will be referred to as the &ldquo;Client&rdquo;.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>2. The Companies Obligations.</strong></p>
        <p style={myP1}>2.1. As a company who provides Vehicles for Hire, in accordance with our Terms and Conditions (from here on referred to as T&amp;C&rsquo;s) we have the following obligations.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;To provide a Vehicle and any additional services in accordance with an agreed upon booking.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;To ensure the vehicle is mechanically road worthy.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;To ensure the vehicle is clean both inside and out.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;To provide T&amp;C&rsquo;s to be agreed upon instruction, inclusive of:</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Inspections Policy.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Fuel and Ad-Blue Policy.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Cancellation Policy.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Tracker Systems Policy.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Disputes and Complaints Policy.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Hire Agreement.</p>
        <p style={myP1}>2.2. To make you aware of other services that may be available and applicable for your needs which we will ensure are provided if agreed upon at an extra charge.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>3. The Contract.</strong></p>
        <p style={myP1}>3.1. The relationship between the client and the company is governed by these T&amp;C&rsquo;s together with all related documents which, once agreed upon and signed, forms a legally binding contract between us and will govern the use of the hire vehicle during its rental period in accordance with these T&amp;C&rsquo;s:</p>
        <p style={myP1}>3.2. Documents include:</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The booking confirmation email (where applicable)</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Rental Agreement</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Condition Reports (If Applicable)</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Invoices of Repairs for damages.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Guide to additional costs</p>
        <p style={myP1}>All of which come to form the whole contract of hire between the Company and the Client. Upon conflict between any of the above documents, these T&amp;C&rsquo;s will take priority in resolving any issues that may arise.</p>
        <p style={myP1}>If any provisions, or part of a provision within these T&amp;C&rsquo;s or any other related contractual documents are deemed by any court or relevant authority to be unlawful, invalid, or unenforceable. &nbsp;The remaining provisions (or part of said provisions) will continue to be contractually enforceable.</p>
        <p style={myP1}>3.3. If you are a corporate account which is looking to take services for which a credit agreement is in place. The specific contractual documents forming the contract between us must be read in conjunction with any Corporate Agreement that may exist between the parties. &nbsp;If there are any inconsistencies the provision of the Corporate Agreement will take priority.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>4. To whom does these T&amp;Cs apply?</strong></p>
        <p style={myP1}>4.1. The company and their obligations.</p>
        <p style={myP1}>4.2. The client as you are the person who is paying for the rental and any associated costs.</p>
        <p style={myP1}>4.3. If you are a corporate account with our Company, then the Named Driver and the Corporation in line with the Corporate Agreement.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>5. Who we Rent to.</strong></p>
        <p style={myP1}>5.1.1. We will provide services to anyone who fits within the following criteria:</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Is 21+</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Has had a full driving licence for 1 year.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Is legally capable of entering into a legally binding contract and is prepared to accept responsibility for the Vehicle throughout the Hire Period.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Has the means to pay for the hire of the Vehicle and any associated charges, fees.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Can pay via the following means only:</p>
        <p style={myP2}>a: Credit Cards</p>
        <p style={myP2}>b: Debit Cards</p>
        <p style={myP2}>c: Bank Transfer</p>
        <p style={myP2}>d: Deduction from client&apos;s Skilled Solutions account (if applicable)</p>
        <p style={myP1}>5.1.2.&nbsp; &nbsp; &nbsp;Endorsement Restriction.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; No more than 6 penalty points (endorsements) on their driving license.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; has not committed more than 3 driving related offences of any kind in the last 4 years.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; does not currently have penalty points (endorsements) on their driving licence in relation to Offence Codes CD40, CD50, CD60, CD70, CD80 or CD90.</p>
        <p style={myP3}>&bull; does not currently have penalty points (endorsements) on their driving licence in relation to Offence Codes DD40, DD60, DD80, DD90;</p>
        <p style={myP3}>&bull; does not currently have penalty points (endorsements) on their driving licence in relation to Offence Codes DR10, DR20, DR30, DR40, DR50, DR60, DR70, DR80,DR90;</p>
        <p style={myP3}>&bull; does not have any AC offence codes on their licence.</p>
        <p style={myP3}>&bull; does not have any UT codes on their licence</p>
        <p style={myP3}>&bull; does not currently have penalty points (endorsements) on their driving license in relation to Offence Codes IN10, MS40, MS50, MS60, MS70, MS80 or TT99.</p>
        <p style={myP3}>&bull; is not currently disqualified from driving (this includes having their license suspended); and</p>
        <p style={myP3}>&bull; does not have any unspent convictions on their criminal record (driving related or other).</p>
        <p style={myP1}>5.1.3.&nbsp; &nbsp; &nbsp;Documents required to obtain Services.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;I.D. (Additional ID may be requested)</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Passport (May be Requested)</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Driving Licence &nbsp;(Mandatory)</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Confirmation of Address (Always requested)</p>
        <p style={myP1}>5.2. Approving you for our Services.</p>
        <p style={myP1}>5.2.1. Living in the UK: To take on a contract through the Company for Hiring a Vehicle. &nbsp;We will check your photographic ID (Driving licence, Passport, National I.D. Card. We may also:</p>
        <p style={myP1}>5.2.1.1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Confirm your identity and home address by using a third-party authentication checking system. On occasion we may need you to provide paper-based proof of identity such as a utility bill or council tax statement dated with 3 months of the proposed Hire Start Date. It is recommended that these documents are available all the way through the New Hire Process, as if they are required and not to hand, this may cause delay in hiring the vehicle.</p>
        <p style={myP1}>5.2.1.2. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Make additional checks for the purpose of preventing fraud and money laundering. &nbsp;This may include checking past accident claims and motor vehicle history via a third-party fraud prevention and detection database.</p>
        <p style={myP1}>When we make these enquiries, we and any third-party agency process your data due to a legitimate interest in preventing and detecting fraud and verifying your identity before providing you with a vehicle.</p>
        <p style={myP1}>If these further checks retrieve a negative outcome, even in the event of your I.D. and home address being confirmed, we will be unable to provide you with a Hire Agreement.</p>
        <p style={myP1}>5.2.1.3. If you live outside of the UK or have no permanent UK address, we will be unable to provide services to you at this time.</p>
        <p style={myP1}>5.3. Who can Drive our Vehicles.</p>
        <p style={myP1}>The appointed Driver of the Vehicle will be any person who has been authorised by us or the Organisers of a Corporate Contact where applicable whom complies with the following requirements:</p>
        <p style={myP1}>5.3.1. They are directly mentioned and fully identified on the Hire Agreement provided by us or the Corporate Account Holders.</p>
        <p style={myP1}>5.3.2. having provided a valid, full driving licence and valid identification documents in accordance with section 5.2.</p>
        <p style={myP1}>5.3.3.1. UK Driving Licence Holders: Must have held a full valid driving licence for a minimum of 12 months and is in line with our endorsement restrictions with a maximum of 6 points on their licence (section 5.1.2)</p>
        <p style={myP1}>5.3.3.2. The company will undertake a DVLA check at the point of hire. &nbsp;For this the Client will be asked to provide proof of their Driving Licence number, National Insurance number and Home Post Code. &nbsp;If endorsements are found and in contravention of our ability to hire a vehicle, we reserve the right to refuse service.</p>
        <p style={myP1}>5.3.3.3. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;The company will undertake DVLA checks each time a Hire Agreement reaches its renewal date. &nbsp;If new endorsements result in more than 6 points identified, we shall terminate the terms of our agreement with immediate effect and if any funds are outstanding, we will seek to collect them. Equally if funds are due to be refunded to the client, this will be done in line with our Refunds Process.</p>
        <p style={myP1}>5.3.4. Non-UK driving Licence Holders: Must have held a full, valid driving licence for a minimum of 12 months. Overseas Licences must be clearly identifiable as a driving licence, or the provision of an International Driving Permit will be essential.</p>
        <p style={myP1}>5.3.4. As well as the above, the following documentation must also be provided:</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Passport</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Proof of having a UK residence.</p>
        <p style={myP1}>5.4. Unauthorised Drivers</p>
        <p style={myP1}>5.4.1. &ldquo;Unauthorised Driver&rdquo; refers to anyone who is not specifically mentioned within the hire agreement. (See section 5.3).</p>
        <p style={myP1}>5.4.2. Any person who cannot provide the relevant identification documents (See section 5.1.3.)</p>
        <p style={myP1}>5.4.3. An Unauthorised driver will not be insured or protected by the products that we offer.</p>
        <p style={myP1}>5.4.4. If an Unauthorized Driver is appointed by the person who has taken the Hire Agreement, it will be considered a breach of the Contract and the named person on the contract will be responsible for all consequences that may arise as a result.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>6. Where you can drive our vehicles</strong></p>
        <p style={myP1}>6.1. Our vehicles are not to be taken outside of Mainland UK. At this time there are no exceptions, and it will be in breach of the Contract should the vehicle be taken out of the agreed upon territory.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>7. The Client&rsquo;s Obligations toward the vehicle.</strong></p>
        <p style={myP1}>During the Hire Agreement from the Company both the Client and Named Driver (each of whom for the purposes for this section will be referred to as &ldquo;You&rdquo;) Must comply with the following obligations.</p>
        <p style={myP1}>7.1. To return the Vehicle, its keys, all accessories provided and documentation to us at the end of the contract (this includes when the collection service is utilized).</p>
        <p style={myP1}>7.1.1. To return the vehicle to a specified location / office agreed with the company at the point of hire (unless when the collection service is utilized)</p>
        <p style={myP1}>7.1.2. By the expiry time of the contract.</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If vehicles are retuned prior to 12pm you will not be charged for that days Hire.</p>
        <div style={myP1}>
            <ul style={{marginBottom:"0cm", listStyleType: "disc"}}>
                <li style={myP1}>If vehicles are returned after 12pm charges will remain in place to cover loss of service.</li>
            </ul>
        </div>
        <p style={myP1}>7.1.3. Fuel levels will be recorded on the Condition Report and Hire Agreement, and you will only be held liable to return the vehicle with a similar level of fuel that was provided at the time of Hire.</p>
        <p style={myP1}>If the Vehicle is returned without the above provisions, we will take every measure available within these T&amp;Cs to recover the items or associated costs.</p>
        <p style={myP1}>7.2. To never drive the vehicle outside of Mainland UK.</p>
        <p style={myP1}>7.3. You are responsible to Drive the vehicle in accordance with all applicable road traffic laws. (Road Traffic Act 1991 &amp;Traffic Management Act 2004)</p>
        <p style={myP1}>7.4. Ensure that any goods in transport within the vehicle are secured to the extent that they will not cause any damage to the vehicle or cause risk to any third parties or third-party property.</p>
        <p style={myP1}>7.5. The vehicle must be:</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Treated with due care</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Respect</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Locked and protected by its anti-theft devices when left unattended.</p>
        <p style={myP1}>7.6. You must never drive the vehicle whilst under the influence of:</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Alcohol</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Hallucinatory Drugs</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Narcotics</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Barbiturates</p>
        <p style={myP1}>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;any other Illegal or legal substances that could impair your driving ability.</p>
        <p style={myP1}>7.7. Not to modify the vehicle in any way during the rental agreement which includes but not limited to the securing of roof racks, bike racks etc. &nbsp;Should you fit winter tyres during the rental agreement, you may be liable for any charges relating to damage or costs associated with us changing the tyres back to the industry standard.</p>
        <p style={myP1}>7.8. Not to smoke, use e-cigarettes or any form of vaping device within the vehicle or allow anyone else to do so. If we are given reason to believe that smoking or vaping has happened, you will be liable to pay our Valet Fee &ndash; Standard or, depending on the condition of the interior you may be liable for our Valet Fee &ndash; Deep Cleaning.</p>
        <p style={myP1}>7.9. Refuel the vehicle with the correct Fuel type. If unsuitable fuel is placed in our vehicles, you will be responsible for all reasonable expenses relating to the recovery and repair of the vehicle that was leased to you.</p>
        <p style={myP1}>7.10. To make routine inspection to ensure that the correct fluid levels are maintained within the vehicle and take any preventative action to keep the vehicle in good working condition.</p>
        <p style={myP1}>7.11. Attend routine inspections throughout the duration of the Hire Agreement. &nbsp;These will be organised by our administrators and will be required as per the schedule below date of initial hire.</p>
        <ul className="decimal_type" style={{listStyleSype: "disc"}}>
            <li>Video Inspections every 28 days submitted through the skilledSolutions.co.uk website, unless contracted by Skilled Solutions where an on-site supervisor or person appointed by the on-site supervisor will conduct the video inspection on your behalf every 14 days.</li>
            <li>Physical Inspections every 90 days at the companies&rsquo; head office, which is 20 Nobel Square, Basildon, Essex, SS13 1LP.</li>
            <li>Ad-Hoc Inspections can be requested by us if we suspect their may be a breach of these terms and conditions and will require a physical inspection to be completed at the companies&rsquo; head office which is 20 Nobel Square, Basildon, Essex, SS13 1LP at the earliest booked time.</li>
            <li>In the event of incident being reported, you will be requested to attend the must companies head office, which is 20 Nobel Square, Basildon, Essex, SS13 1LP at the earliest booked time.</li>
        </ul>
        <p style={myP1}>7.12. Do not use the Vehicle nor allow the Vehicle to be used:</p>
        <p style={myP1}>&bull; For Rehire</p>
        <p style={myP1}>&bull; Mortgage collateral</p>
        <p style={myP1}>&bull; Pawn</p>
        <p style={myP1}>&bull; Sell</p>
        <p style={myP1}>&bull; Attempt to give legal rights to the vehicle or any of its parts and accessories.</p>
        <p style={myP1}>7.12.2. To carry passengers for hire or reward.</p>
        <p style={myP1}>7.12.3. To carry excessive numbers of passengers not in line with the manufacturer&rsquo;s specification.</p>
        <p style={myP1}>7.12.4. To carry flammable and/or dangerous merchandise that may be harmful and / radioactive products or those that may infringe local laws and regulations or to transport merchandise with a weight quantity or volume that is more than the vehicle manufacturer specification.</p>
        <p style={myP1}>7.12.5. For racing on or off road, off roading, reliability trials, speed testing or to take part in rallies, contents, or trials wherever they are located (official or not).</p>
        <p style={myP1}>7.12.6. To transport live animal except for assistance dogs which are subject to our written consent prior to being undertaken.</p>
        <p style={myP1}>7.12.7. To give driving lessons.</p>
        <p style={myP1}>7.12.8. To push or tow another vehicle or trailer.</p>
        <p style={myP1}>7.12.9. On roads that are not suitable for such vehicles to be driven on. i.e., Gravel roads, beaches, forest paths.</p>
        <p style={myP1}>7.12.10. To cross fords or other areas of water only when safe to do so.</p>
        <p style={myP1}>7.12.11. to intentionally commit an offence.</p>
        <p style={myP1}>7.13. Your obligations upon returning the vehicle.</p>
        <p style={myP1}>7.13.1. Return the vehicle, its keys and documentation as specified in section 7.1.</p>
        <p style={myP1}>7.13.2. The daily charge for each day or part day that you keep the vehicle past the expiry time and date of the Hire agreement as well as an unauthorized extension charge will be applicable as set out within the Rate Card.&lt;/p&gt;</p>
        <p style={myP1}>7.13.3. For damage caused to the vehicle if any during the period of hire the client will be responsible for all reasonable expenses relating to the recovery and repair of the vehicle that hire to the client, accepted evidence of the vehicles pre-existing condition and condition on return will include but not limited to hire agreements, condition reports and video inspections.</p>
        <p style={myP1}>7.13.4. is to provide the same level of fuel that was provided at the start of the Hire Agreement. If a variation is identified this will be charged at the current fuel costs as set out in our Rate Card.</p>
        <p style={myP1}>7.14. If you fail in your obligations.</p>
        <p style={myP1}>7.14.1. You must not allow any unauthorized driver to use the vehicle either intentionally or unintentionally through negligence or failure to act.</p>
        <p style={myP1}>If you do not fulfil any or all these obligations, then it may invalidate the insurance and protection provisions provided by the company. &nbsp;In these circumstances you will be responsible for and will pay to us all reasonable costs of any detrimental consequences, loss and /or damage that may arose as a result such failures.</p>
        <p style={myP1}>7.14.2. The company reserves the right to demand to the immediate return or collection of the vehicle if the contracted and/or optional insurance coverage and services are compromised or invalidated.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>8. Other Charges and Fees that may have to be paid.</strong></p>
        <p style={myP1}>8.1. We may charge you for various incidents that may occur during your rental agreement because of how the vehicle is used. &nbsp;For Example: Should we receive a Fixed Penalty Notice / Penalty Charge Notice / Parking Charge Notice (from here on referred to as an FPN/PCN) or if you fail to pay at Toll Crossings such as the Dartford Crossing for which we receive the charges, this will be re-charged to you along with an administration charge which is outlined within the Rate Card.</p>
        <p style={myP1}>8.1.2. Fines and Penalties&lt;/b&gt;</p>
        <p style={myP1}>8.1.2.1. This section and from here on the term &ldquo;Issuing Party&rdquo; is in relevance to authoritarian bodies who have the legal power to issue fines and penalties. &nbsp;Such as:</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Police and other enforcement agencies.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Either public or private enforcement agencies that is entitled to issue fines.</p>
        <p style={myP1}>8.1.2.3. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;You are and will be responsible for paying all charges arising from:</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Parking Charges</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Congestion</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; ULEZ</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; A breach of any parking restrictions</p>
        <p style={myP4}>&bull; Road traffic offences (moving contraventions) whilst within our vehicle which include (but not limited to)</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;a: Lane Infringement.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;b: Tunnel.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;c: Turning.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;d: Bus Lane use charges.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;e: Vehicle being Clamped.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;f: Vehicle being seized, towed and / or impounded.</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;g: and any other subsequent charge caused by improper use of our Vehicles.</p>
        <p style={myP1}>8.1.2.4. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;If the vehicle is not reported stolen or is being driven by an authorized approved Driver, you are and will be primarily responsible for settling any charges that may occur during the Hire Agreement. You agree that from the date of the hire agreement that the Company can notify the Issuing Party of your personal details to affect a transfer of liability.</p>
        <p style={myP1}>8.1.2.5. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;If the Company is required to deal with said correspondence from an Issuing party. We will charge the &ldquo;Third party administration fee&rdquo; in line with the Rate Card.</p>
        <p style={myP1}>8.1.2.6. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;We will always seek to redirect the FPN/PCN or Road Traffic contravention charge to the relevant party. &nbsp;This will not ever be paid by the Company although in each event the company will apply the Third-Party Administration Charge and make you aware within 2 working days of receiving the notice.</p>
        <p style={myP1}>8.1.2.7. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;For each reported incident towards an insurance claim an excess fee (listed in our Rate Card) will be charged whether the client is at fault or not, in the event the claim is closed and we as the supplier have not incurred any fee&apos;s/charges it is the managers discretion whether to refund the excess charged.</p>
        <p style={myP1}>8.1.2.8. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;The excess is per event and not per hire.</p>
        <p style={myP1}>8.1.2.9. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;For each reported incident later than 48 hours the company may apply a failure to report charge as listed on our Rate Card, this is at the managers discretion.</p>
        <p style={myP1}>8.1.2.10. In each event an invoice for the Third-Party administration charge will be produced and provided to you.</p>
        <p style={myP1}>8.1.3. Vehicle Hire and Deposit</p>
        <p style={myP1}>8.1.3.1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Every vehicle has a different weekly hire rate, and this will be confirmed with the client at the time of collection or before the arranged delivery.</p>
        <p style={myP1}>8.1.3.2. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Every vehicle has a deposit fee chargeable per hire, this is refunded 28 days after the hire if the company has not incurred and fees as a direct result of the hirer during their period of hire.</p>
        <p style={myP1}>8.1.3.3. Unless otherwise agreed the hire and deposit must be paid in advance.</p>
        <p style={myP1}>8.1.4. Telematics Assisted Charges</p>
        <p style={myP1}>8.1.4.1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Using the telematics devices detailed in section 16 the company may use the data received to identify clients who flag as a potential on road risk and as such may apply a penalty to the weekly hire charge, this is at the managers discretion and their judgement is final, see the Rate Card for more details.</p>
        <p style={myP1}>8.1.4.2. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Using the telematics devices detailed in section 16 the company may use the data received to identify clients who have exceptional driver behaviour and as such may apply a discount to the weekly hire charge, this is at the managers discretion and their judgement is final, see the Rate Card for more details.</p>
        <p style={myP1}>8.1.5. Other Charges and Fees</p>
        <p style={myP1}>8.1.5.1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;All other charges and fees are outlined within the Rate Card, these include but not limited to administration fees, valeting, specialist cleaning, damage and repair costs, delivery and collection charges, wrong fuel charges, excess mileage charge. You agree that you are responsible for the payment of all charges and fees detailed in section 10 and from within the Rate Card where they have been applied in line with these Terms and Conditions. &nbsp;If charges are applied but there is refusal to settle the charges, we may terminate the contract and demand the immediate return of the vehicle.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>9. What you need to do when you pick up the vehicle</strong></p>
        <p style={myP1}>9.1. When you come to collect the vehicle, you will be required to sign a hire agreement and you will receive a condition report which details any current defects or damage the vehicle may have. &nbsp;Before you sign you should:</p>
        <p style={myP1}>9.1.1. Inspect the vehicle and any accessories for pre-existing damage.</p>
        <p style={myP1}>9.1.2. Check the fuel tank is full.</p>
        <p style={myP1}>9.2. When it is not possible to check for pre-existing damage on the vehicle and its accessories at the time of collection you must notify us within 24 hours of the start of the Hire Agreement. &nbsp;Please take pictures of the defect / damage with a brief description of the type of damage and the location. &nbsp;Please then send them to queries@skso.co.uk within 24 hours of collection.</p>
        <p style={myP1}>9.3. If you don&rsquo;t notify us of any pre-existing damage, then we will assume that you have accepted the vehicle and its accessories in the condition set out in the Hire Agreement / Condition Report. &nbsp;If these unreported defects are discovered when the vehicle comes in for inspection, we will charge you for repairs where necessary.</p>
        <p style={myP1}>9.4. Familiarise yourself with the vehicle and its controls prior to driving on public highways. &nbsp; Make sure you know where important controls are located such as Headlights, Fog Lights, Windscreen heaters etc.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>10. When you return the vehicle.</strong></p>
        <p style={myP1}>10.1. General Returns.</p>
        <p style={myP1}>10.1.1. You are encouraged to return the vehicle to the Head Office location at 20 Nobel Square, Basildon, Essex SS13 1LP. However, if arranged with the Head Office it may be agreed that you may drop off the vehicle at one of our depots, should this happen, it will always incur our Collection Charge listed is within our Rate Card.</p>
        <p style={myP1}>10.1.2. The vehicle should arrive at the specified location before to 12pm to avoid the rental charge that could be applied for a late return. &nbsp;The Extended Hire Charge will apply and a calculated at a day rate if the vehicle is returned after 12.pm and will continue to be applied from 12pm on all subsequent days for late returns.</p>
        <p style={myP1}>10.1.3. When our collection service is used, you must be present with the vehicle, keys, and all associated accessories for an agreed upon time window of up to 1 hours. &nbsp;The vehicle must not be left in a position in which it may receive fines or charges of any kind.</p>
        <p style={myP1}>10.1.4. Once the vehicle is returned (whether by Head office drop off or collection.) Should we discover beyond reasonable doubt that the vehicle has been used to carry Raw or Hazardous materials/waste or it still contains said Raw, Hazardous materials then we will not accept its return. Instead:</p>
        <p style={myP1}>10.1.4.1. The contract will be extended, and the vehicle will remain on hire to you.</p>
        <p style={myP1}>10.1.4.2. During this extension you will be required to dispose of all such Materials deemed to be Hazardous. &nbsp;You will also ensure the interior is clean and safe for us and future clients before we accept the return of the vehicle.</p>
        <p style={myP1}>10.1.4.3. Within these circumstances, upon return of the vehicle after it has been appropriately emptied and cleaned. &nbsp;We (meaning the Company and Client) will undertake an off-hire inspection together, after that the vehicle will have a full assessment carried out within 2 working days and the vehicle will not be re-rented until such time an assessment is complete.</p>
        <p style={myP1}>10.1.4.3.1. If, in our reasonable opinion the Vehicle is in a suitable condition for return, we shall accept the return and rental charges will discontinue.</p>
        <p style={myP1}>10.1.4.3.2. If, in our reasonable opinion the vehicle requires further attention (whether valet - standard or valet - deep clean) You will be charged accordingly in line with our Valet and Specialist cleaning charges as set out in the Rate Card.</p>
        <p style={myP1}>10.1.5. We shall check the fuel level and Ad-Blue (if required) of the vehicle against the level recorded at the time of New Hire. &nbsp;You are responsible for the fuel and Ad-Blue used during your Hire Agreement and to ensure that the vehicle is returned with a full tank (unless agreed and recorded it was at a different level at the time the Hire Agreement began.</p>
        <p style={myP1}>10.1.5.1. Should we need to add fuel once the vehicle has been returned, this will be charged at the current market value and the cost will be provided to you via invoice.</p>
        <p style={myP1}>10.1.5.2. Should we need to add Ad-Blue once the vehicle has been returned, this will be charged at the current market value and the cost will be provided to you via an invoice with a minimum charge for 5 litres unless it is not required.</p>
        <p style={myP1}>10.1.6.&nbsp; &nbsp;Personal Property.</p>
        <p style={myP1}>The Company is not responsible for any loss off / damage to, any belongings that have been placed inside or on the vehicle. &nbsp;Any items not recorded to be part of the Hire Agreement will always be your (the Clients) responsibility. &nbsp;Any items found within the vehicle will be kept for a period of 4 weeks after we have notified you by email that item remained in the vehicle. &nbsp;If they remain uncollected past this 4-week period (unless otherwise agreed) they will be disposed of.</p>
        <p style={myP1}>10.1.7. Early Returns.</p>
        <p style={myP1}>Should you wish your contract to end prior to the agreed upon period. &nbsp;Your refund will be calculated by breaking the charges down to a day rate to ensure an accurate amount is refunded to you.</p>
        <p style={myP1}>10.1.8.&nbsp; &nbsp;Late Returns.</p>
        <p style={myP1}>The Company policy is that all returns must be made prior to 12pm to avoid the rental charge for the day of return. Should the vehicle be returned after 12.pm you will be liable for the daily rental charge for that day.</p>
        <p style={myP1}>10.1.8.1. Should the vehicle not be returned on the date that was agreed in the Terms of the Hire Agreement and you have not contacted us, and we are unable to contact you for a period of 24 hours after the end time of your Hire Agreement, we will regard the vehicle as Stolen and will report this to the Police. &nbsp;We shall pursue all lawful means to recover the vehicle which can include repossessing it or applying a court order requiring you to return it and/or pay us an equivalent to the vehicles market value. &nbsp;Taking such steps will incur the following provisions which is agreed between all Parties at the time of Hire and cannot be withdrawn.</p>
        <p style={myP1}>10.1.8.1.1. You give us permission to access your premises for the purpose of repossessing the Vehicle, though we will not use force nor cause Damage during the repossession.</p>
        <p style={myP1}>10.1.8.1.2. You will be liable to pay charges and fees set out in the Rate Card plus any circumstantial legal costs incurred that can lawfully be placed upon you.</p>
        <p style={myP1}>10.2 When you return a vehicle you should:</p>
        <p style={myP1}>10.2.1. Take the opportunity to ensure all personal belonging are removed.</p>
        <p style={myP1}>10.2.2. With our Fleet Assistant and sign any relevant documentation that we (meaning both Company and Client) identify during the Off Hire Inspection process.</p>
        <p style={myP1}>10.3. Unattended Return of the vehicle.</p>
        <p style={myP1}>10.3.1. If you return the vehicle Out of Hours or are unable to remain on-site during the return&rsquo;s inspection. You automatically accept that we will inspect the vehicle on our own and any damages identified to have happened during your Hire Agreement will be charged to you accordingly. &nbsp;This can include Valeting and Special Cleaning charges when applicable.</p>
        <p style={myP1}>The Company recommends that under these circumstances you take detailed pictures of the vehicle and its final parking place as evidence to its condition at the time you returned it.</p>
        <p style={myP1}>10.3.2. The company is not responsible for any loss, theft, or damage of any nature, relating to any items / belongings / equipment that have been transported or left in or on the vehicle.</p>
        <p style={myP1}>10.4.1 The customer has the right to return the vehicle with immediate effect and the return will be processed in line with the terms and conditions, the customer will remain liable for any charges incurred.</p>
        <p style={myP1}>10.4.2 The supplier has the right to terminate the vehicle hire and request the return of the vehicle with immediate effect and the vehicle will be processed in line with the terms and conditions, the customer remain liable for any charges incurred.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>11.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Damage to the Vehicle.</strong></p>
        <p style={myP1}>11.1. Our vehicles are our assets, and we will make every attempt to recover costs for damage and inappropriate use of our assets. As such by entering this contract you permit us, in the event of costs not being recoverable directly by the supplier to provide only the relevant Personal Information and Contractual Information to Third Party Debt recovery companies.</p>
        <p style={myP1}>11.2. As outlined in section 7 (The Clients Obligations to the Vehicle) and specifically 7.1 you are obligated to return (or make available for collection) the Vehicle, its Keys, accessories, and documentation to us in the same condition as they were rented to you excluding what can be reasonably applied as &ldquo;Fair wear and Tear&rdquo;.</p>
        <p style={myP1}>11.2.1. We will and are entitled to repair our vehicles at our convenience to restore it to its condition at the time of the Hire agreement.</p>
        <p style={myP1}>11.2.2. You are liable to pay all damage charges for works that require completion regardless of whether the damage was caused by you, a third party or local authority during the removal and impounding of our vehicle unless:</p>
        <p style={myP1}>11.2.2.1. The damage was caused by our fault, negligence, or breach of this contract.</p>
        <p style={myP1}>11.2.2.2. We have received a payment from a Liable Third party or their insurers that cover the full cost of repair.</p>
        <p style={myP1}>11.2.2.3. Under these circumstances, if we have recovered costs from yourself but have also been successful in claiming funds from a third party or their insurers. &nbsp;We shall reimburse funds to you.</p>
        <p style={myP1}>11.3. Damage identified during an Assessment or return inspection (Company and Client)</p>
        <p style={myP1}>11.3.1. If Damage is identified upon return of the vehicle when undertaking a Joint Inspection. &nbsp;We encourage you (the client) to join our Fleet Assistant to look through the Condition Report that was provided at the time of the New Hire Agreement in line with these T&amp;C&rsquo;s.</p>
        <p style={myP1}>11.3.2. We shall provide you with an invoice of expected costs in line with current market rates. &nbsp; Should the costs come under the value of the estimate (subject to relevant Admin and Labour costs) we shall reimburse funds to you, equally should the repair costs go above our initial estimate, we shall seek to recharge you for the outstanding balance.</p>
        <p style={myP1}>11.3.3. We are under no obligation to provide invoices and/or estimates from our suppliers and any invoices raised are final.</p>
        <p style={myP1}>11.4. If you contest the Damage, the invoice, if you refuse to sign the statement of the return for the vehicle if the vehicle qualifies as an Unattended Return or if for whatever reason we are unable to apply a cost for the repair. We will continue our process in accordance with section 11.5 and you will have the option to pursue the section 11.6 to dispute the damage claims.</p>
        <p style={myP1}>11.5. Damage identified in your absence at a return inspection.</p>
        <p style={myP1}>11.5.1. &nbsp;If damage is identified in your absence during our inspection, we shall send you the following documents by email or post.</p>
        <p style={myP1}>11.5.2. The condition report detailing all the damage identified.</p>
        <p style={myP1}>11.5.3. Pictures of the damage and how this varies from the New Hire Condition Report.</p>
        <p style={myP1}>11.5.4. An invoice detailing the applicable charges.</p>
        <p style={myP1}>11.6. Querying / Disputing Damage Charges.</p>
        <p style={myP1}>11.6.1. If you wish to query or dispute any charges applied after the return of the vehicle and the email/letters have been sent. You have 2 days from the date of the email / letter to make us aware of your dispute and provide any evidence you believe is relevant to disputing the charges.</p>
        <p style={myP1}>11.7. Damage Charges</p>
        <p style={myP1}>11.7.1. Light Damage</p>
        <p style={myP1}>11.7.1.1. Damage sustained to the vehicle during its Hire Agreement with the Client which, in our reasonable opinion is minor damage and therefore does not necessarily need immediate repair due to it not effecting the safety of the vehicle. &nbsp;Only cosmetically will be classed as Light Damage Repair Schedule.</p>
        <p style={myP1}>11.7.2.1. If we have assessed the damage to qualify as Light Damage and as such does not need immediate repair before being rented to another Client. &nbsp;In these circumstances in conjunction with section 11.4 you will remain liable for</p>
        <p style={myP1}>11.7.2.2. The sums associated with the identified damage provided to you within the subsequent invoice.</p>
        <p style={myP1}>11.7.2.3. The associated administration costs.</p>
        <p style={myP1}>11.7.2.4. In the case of Light Damage where a repair is not required for the vehicle to be re-rented. &nbsp; You will not be charged for loss of service.</p>
        <p style={myP1}>11.8.&nbsp; &nbsp; &nbsp;&nbsp;Serious Damage</p>
        <p style={myP1}>11.8.1. Any damage other than Light Damage to our Vehicles will be classified as &ldquo;Serious Damage&rdquo; This is defined by the need to have the vehicle repaired prior to re-renting the vehicle. &nbsp;For example: Replacement wing mirrors, broken Brake Light Clusters etc.</p>
        <p style={myP1}>11.8.2. If serious damage is identified to have happened during your Hire Agreement, you will.</p>
        <p style={myP1}>11.8.3. Pay us the sums we are liable to pay our suppliers in the recovery / repair / cleaning and making ready the vehicle for re-hire for which will compensate us for the debt. And:</p>
        <p style={myP1}>11.8.3.1. Pay to us the following charges when applicable:</p>
        <p style={myP1}>11.8.3.1.1. A Loss of Use Charge which is to consider our loss of income due to not being able to re-rent the vehicle. This is itemised on our Rate Card:</p>
        <p style={myP1}>11.8.3.1.2. For damage to tyres and windscreen, 1 day Loss of Use Charge will be applied.</p>
        <p style={myP1}>11.8.3.1.2.1. For Serious Damage to the Tyres or Windscreens any fee&apos;s incurred by an engineer may be applied.</p>
        <p style={myP1}>11.8.3.1.2.2. An administration fee as set out in our Rate Card.</p>
        <p style={myP1}>11.9. We inform you that:</p>
        <p style={myP1}>11.9.1. Where a repair or part replacement may likely put the Vehicle in a better condition that it was at the start of the Hire Agreement between us. &nbsp;The amount for which you are liable to us will not be reduced nor refunded to reflect the old for new replacement.</p>
        <p style={myP1}>11.10. Tyre replacement and / or repair.</p>
        <p style={myP1}>11.10.1. No charges are applied for fair wear and tear to the tyres and replacement are provided by the Company.</p>
        <p style={myP1}>11.10.2. Damage to the tyre that requires the tyre to be replaced will be classified as a &ldquo;Tyre Replacement&rdquo;. &nbsp;If a tyre has a repairable puncture, then it will be classified as a &ldquo;Tyre Repair.&rdquo;</p>
        <p style={myP1}>11.10.3. When a tyre needs repair or replacement you will pay us for the charges that we would incur from our supplier.</p>
        <p style={myP1}>11.10.4. An administration charge will also be applied at our standard rate found within the Rate Card.&nbsp; &nbsp;&nbsp;</p>
        <p style={myP1}>11.11. Windscreen Replacement or Repair.</p>
        <p style={myP1}>11.11.1. Any damage to the windscreen which requires a replacement will be referred to as a Windscreen Replacement. &nbsp;Small cracks and chips in the windscreen which can be repaired will be referred to as a Windscreen Repair.</p>
        <p style={myP1}>11.11.2. Neither crack nor chip is justified as fair wear and tear.</p>
        <p style={myP1}>11.11.3. For windscreen replacements you will pay the invoice amount, there is no reduction or excess to reduce the costs.</p>
        <p style={myP1}>11.11.3.1. and any associated supplementary charges that may apply,</p>
        <p style={myP1}>11.11.3.2. and the standard administration charge set out within our Rate Card.</p>
        <p style={myP1}>11.12. Total Loss (written off) Vehicles</p>
        <p style={myP1}>11.12.1. In the event and under the reasonable assessment that the vehicle is beyond economical repair and / or we have been advised by our engineers that the vehicle will not be cost effective to repair. &nbsp;Section 11.8 and 11.9 and its subsections will not apply, and you will pay:</p>
        <p style={myP1}>11.12.2. The amount (for which will indemnify us as a debt) representing the value of the Vehicle and any Vehicle recovery charges, minus any sums we are able to recoup should the vehicle be sold for salvage; and</p>
        <p style={myP1}>11.12.3. The Loss of Use Charge which is chargeable at your daily rental charge from the date your Hire Agreement ended until we can sell the vehicle for salvage.</p>
        <p style={myP1}>11.12.4. An Engineers Charge which is set out in the Rate Card.</p>
        <p style={myP1}>11.12.5. The standard administration charge bas set out within out Rate Card.</p>
        <p style={myP1}>11.13. Damage to Third Parties</p>
        <p style={myP1}>11.13.1. You are responsible for contacting The supplier in the event of an accident of any kind within 48hrs unless force majeure prevents it. &nbsp;The Insurance is provided by us and once notified, we will undertake the necessary steps to assist in the event of an accident including those that involve a third party.</p>
        <p style={myP1}>11.14. Seizure of the Vehicle</p>
        <p style={myP1}>11.14.1. Once the vehicle is in your care, if the vehicle, its keys, accessories, or documents are seized by a local or private Authorized Party (Government, Police, Private Security with authorization) you will pay:</p>
        <p style={myP1}>11.14.2. for any damage sustained to the vehicle, its parts, accessories, keys, or documents.</p>
        <p style={myP1}>11.14.3. for any fines and charges relating to the seizure.</p>
        <p style={myP1}>11.14.4. and a Loss of Use charge will be applied if the length of seizure exceeds the end date of your Hire Agreement. Unless:</p>
        <p style={myP1}>11.14.4.1. These damages or subsequent costs are our fault due to negligence or breach of contract.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>12. Your obligations toward Vehicle Maintenance.</strong></p>
        <p style={myP1}>12.1. Throughout the Hire Agreement we expect you to take all reasonable steps to safeguard the vehicle from damage to the Vehicle itself, its Keys, accessories, and documents. If you fail to do so you will be charged in accordance with section 11 and the Rate Card.</p>
        <p style={myP1}>12.2. You are expected to maintain all fluid levels where applicable. These include:</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Oil</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Water Levels</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Coolant</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Windscreen washer fluid</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Tyre Pressure</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull; Ad-Blue (if applicable)</p>
        <p style={myP1}>12.3. It is expected for you to check these levels regularly to ensure the vehicle remains in good working condition during your Hire Agreement.</p>
        <p style={myP1}>12.4. You are provided with a Spare Tyre, if a Nail or side wall tear occurs, and the spare tyre gets used, it is the Clients responsibility to have the original tyre repaired. Failure to do this may incur charges.</p>
        <p style={myP1}>12.5. Replacement bulbs for all light units within / on the Van are the clients responsibility to replace during the term of the hire agreement.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>13.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Mechanical Breakdowns, Accidents and Theft.</strong></p>
        <p style={myP1}>13.1. Should a dashboard warning light appear, or the vehicle develops any fault during your hire period, you or the named driver of the vehicle must call the telephone number provided to you at the Hire Agreement.</p>
        <p style={myP1}>13.1.2. If the vehicle breaks down or is involved in an accident / incident during your Hire Agreement we will, as soon as possible recover the vehicle so that it can be repaired to a functional standard.</p>
        <p style={myP1}>13.1.2.1. If we cannot repair the vehicle in a timely fashion, we shall seek to provide you with an alternative vehicle for the remainder of your Hire Agreement at the managers discretion.</p>
        <p style={myP1}>13.1.2.2. If we are unable to provide you with an alternative vehicle, we will provide you with a refund for all unused services up to the date of the Incident.</p>
        <p style={myP1}>13.1.3. If the breakdown is because of our negligence or breach of contract, you will not be charged for recovery and/or repair.</p>
        <p style={myP1}>13.1.3.1. If it is discovered that the breakdown / accident and / or subsequent damage is caused by the negligence or deliberate misuse of the vehicle by you or the Authorised Driver or any unauthorised driver which gained access due to the Clients negligence you will be liable to pay the sums set out in section 13 and your liability will not be capped at the Excess amount. &nbsp;At our discretion, we may accept payment via your insurer.</p>
        <p style={myP1}>13.2. All accidents and Incidents Must be reported.</p>
        <p style={myP1}>13.2.1. If there is an incident, you or the authorised driver must contact us within 48 hours to disclose the details on 01268203504 or email us at queries@skso.co.uk. &nbsp;If you do not contact us or make us aware, you will charge for failing to report an incident as detailed on our Rate Card.</p>
        <p style={myP1}>13.2.2. If you or the authorized driver has an accident / incident. They must:</p>
        <p style={myP1}>13.2.2.1. pay the relevant charges set out in the Rate Card</p>
        <p style={myP1}>13.2.2.2. Not admit or accept responsibility.</p>
        <p style={myP1}>13.2.2.3. Obtain and notify us of the names and addressed of all involved including witnesses.</p>
        <p style={myP1}>13.2.2.4. Make the Vehicle secure and tell the police immediately if anyone is injured or the road is blocked or if there has been any property damage.</p>
        <p style={myP1}>13.2.2.5. Tell us of the accident / incident on 01268203504 or email us at queries@skso.co.uk</p>
        <p style={myP1}>13.2.3.6. Attend our office within 24 hours to complete and return the accident report form that we supply if required.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>14.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Cancellations</strong></p>
        <p style={myP1}>14.1. At this time we do not apply any charges for cancellations. we do ask that if there is a change to you booking that you do contact us and advise us of the change on 01268203504 or email us at queries@skso.co.uk</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>15.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;I want to extend my agreement.</strong></p>
        <p style={myP1}>15.1. Our contracts run on a 1 year-rolling contract with a video inspection every 14 days and a physical inspection at 90 days. &nbsp;It is assumed, if not otherwise disclosed by the Client that the contract will continue until we are advised otherwise.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>16.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Vehicle Telematics</strong></p>
        <p style={myP1}>16.1. We may use electronic equipment installed in the vehicle for the following purposes:</p>
        <p style={myP1}>16.1.1. To manage the rental journey (Delivery, collection, maintenance etc)</p>
        <p style={myP1}>16.1.2. To identify and prevent crime and fraud.</p>
        <p style={myP1}>16.1.3. Ensure that obligations under the rental agreement are met (e.g., if the vehicle is not returned at the end of the hire period, if the vehicle is used outside of the authorised geographical area, such as crossing the border between norther Ireland and the Irish Republic)</p>
        <p style={myP1}>16.1.4. To detect, verify and investigate accidents and damage to the vehicle.</p>
        <p style={myP1}>16.1.5. To assist us in managing our fleet (such as monitoring the vehicle condition, fuel level, operational and diagnostic data, collision alerts etc)</p>
        <p style={myP1}>16.1.6. This information can be used both during and after the end of the hire period.</p>
        <p style={myP1}>16.1.7. Under no circumstances should you disconnect the electronic equipment of disconnection and/or non-return of the equipment, we may charge you additional fees as set out in the Rate Card.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>17.How we use and protect your data.</strong></p>
        <p style={myP1}>17.1. We collect and use your personal information to enable us to provide services to you. This personal information is collected in accordance with all relevant GDPR processes and is updated regularly at each 90-day inspection.</p>
        <p style={myP1}>We will use said data with Third Party Organisations to accordance with our T&amp;Cs to screen drivers for Driving violations that may prohibit you from using our services.</p>
        <p style={myP1}>17.2. You have the right to access, rectify and remove the personal information collected and used in accordance with your vehicle rental service. &nbsp;You can do this by:</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Post. The Data Control Officer. 20 Nobel Square, Basildon, Essex SS13 1LP</p>
        <p style={myP1}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Email: queries@skso.co.uk</p>
        <p style={myP1}>17.3 We reserve the right, and you agree by entering a contract with the supplier that in the event of Non-Recoverable Debt by our in-house process, that we are permitted to provide the relevant Personal and Contractual information to third party Debt Recovery companies so that we may pursue a Debt Recovery Process via a Third Party.</p>
        <p style={myP1}>&nbsp;</p>
        <p style={myP1}><strong>18.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Disputes and complaints procedure</strong></p>
        <p style={myP1}>18.1. If you call us or write to us, we will aim to respond to you within 3 working days of receiving your communication.</p>
        <p style={myP1}>18.1.1. We will always try to contact you via your preferred method of contact whether that be by email, phone, or post.</p>
        <p style={myP1}>18.2. Applicable Law and Jurisdiction.</p>
        <p style={myP1}>In case of any disputes regarding your rental, the only applicable law will be English Law irrespective of your nationality. You agree that the only applicable law will be English Law and subject to the Jurisdiction of the courts in England and Wales.</p>
        <p style={myP1}>18.3. Circumstances out of our Control.</p>
        <p style={myP1}>We will not be in breach of this Hire Agreement or liable to you for the delay in performance or failure to perform any of our obligations under the terms and conditions of the Hire Agreement if circumstances out of our control prevent us from undertaking our duties.</p>

    </div>
    );
  }
}
