import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout.component";
import '../../App.css'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_live_51IgolRA7Ly3TrIf7BMaDuuqHf1lE7cyuSa9bQ6wnXVmZE2cXLRTZRFn7gsWB1cbxm2owP9AmO6mxm6u4ykTiM6dd00s5g6xOfC");

export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  const query = new URLSearchParams(window.location.search);
  const reference = query.get('reference')
  const amount = query.get('amount')
  const firstName = query.get('firstName')
  const lastName = query.get('lastName')
  const address = decodeURI(query.get('address'))
  const town = query.get('town')
  const postCode = query.get('postCode')
  const email = query.get('email')

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("https://node-skso.co.uk/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ amount: amount }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
        <div className="wrapperPayment"> 
            <div className="billing">
                <h2 className="titleL">Details</h2>
                <h3 className="titleC">Name: {firstName+' '+lastName}</h3>
                <h3 className="titleC">Email: {email}</h3>
                <h3 className="titleC">Payment Reference: {reference}</h3>
                <h3 className="titleC">Address: {address}</h3>
                <h3 className="titleC">Town: {town}</h3>
                <h3 className="titleC">Post Code: {postCode}</h3>
                <br></br><br></br>
                <h3 className="titleC">Amount to pay: <span style={{color: 'red'}}>£{amount}</span></h3>
            </div>

            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm amount={amount} reference={reference} email={email} name={firstName+' '+lastName}/>
                </Elements>
            )}
        </div>
    </div>
  );
}