import React, { useEffect, useRef } from "react";
import Select from 'react-select';
import { useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Camera} from "react-camera-pro";
import {QrScanner} from '@yudiel/react-qr-scanner';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '@mui/icons-material/Camera';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SyncIcon from '@mui/icons-material/Sync';
import axios  from "axios";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import UserService from "../../services/user.service";
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import CableIcon from '@mui/icons-material/Cable';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LiveViewer from "./live.component";
import LiveInteriorViewer from "./liveInterios.component";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PublishIcon from '@mui/icons-material/Publish';
import SignatureCanvas from 'react-signature-canvas';
import DrawIcon from '@mui/icons-material/Draw';
import zIndex from "@mui/material/styles/zIndex";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        textAlign: 'center',
      },
      imgBox: {
        maxWidth: "80%",
        maxHeight: "80%",
        margin: "10px"
      },
      img: {
        height: "inherit",
        maxWidth: "inherit",
      },
      input: {
        display: "none"
      }
    }))

    const bull = (
      <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
      >
        •
      </Box>
    );
    const reportOptions = [
      {value: 'Fil Clements', label:'Fil'},
      {value: 'Dan Dewey', label: 'Dan'},
      {value: 'Nikolay Zhelev', label: 'Nik'},
      {value: 'Ventsislav Karaganov', label: 'Ventsi'},
      {value: 'Rob Wells', label: 'Rob'},
      {value: 'Maddison Cross', label: 'Maddison'},
      {value: 'George Willis', label: 'George'},
      {value: 'Ewelina Lukaszewska', label: 'Ewelina'},
      {value: 'Ben Minzat', label: 'Ben'},
      {value: 'Garth James', label:'Garth'}
    ]  
    const optionsDamage = [
        {value: "None", label: "None"},
        {value: "Missing Parts", label: "Missing Parts"},
        {value: "Dents", label: "Dents"},
        {value: "Scratches", label: "Scratches"},
        {value: "Windscreen Chip/Crack", label: "Windscreen Chip/Crack"},
        {value: "Sharp Edges", label: "Sharp Edges"},
        {value: "Cracks", label: "Cracks"}
    ];
    const optionsNopa = [
      {value: 'Greater than 0cm - Less than 3cm', label: 'Greater than 0cm, Less than 3cm'},
      {value: 'Greater than 3cm - Less than 6cm', label:'Greater than 3cm, Less than 6cm'},
      {value: 'Greater than 6cm - Less than 9cm', label: 'Greater than 6cm, Less than 9cm'},
      {value: 'Greater than 9cm - Less than 12cm', label: 'Greater than 9cm, Less than 12cm'},
      {value: 'Greater than 12cm - Less than 25cm', label: 'Greater than 12cm, Less than 25cm'},
      {value: 'Greater than 25cm', label: 'Greater than 25cm'}
    ];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '20% !important', fontSize: '20px', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '100',
        width: '100%',
        minHeight: '100%',
        fontSize: 'x-large',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}
const colourStylesSS = {
  control: styles => ({ ...styles, backgroundColor: 'white', minWidth:'150% !important', minHeight: '73% !important' , fontSize: '20px', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
  menu: base => ({
      ...base,
      zIndex: 100,
      width: '150%',
      minHeight: '100%',
      fontSize: 'x-large',
      paddingLeft:'1%',
      marginLeft:'2%',
  }), 
}
const marks = [
  {value: 0, label: '0'},
  {value: 25, label: '25%'},
  {value: 50, label:'50%'},
  {value: 75, label:'75%'},
  {value: 100, label:'100%'}
];


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 62,
  height: 36,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 42,
    height: 42,
  },
  '& .MuiSwitch-track': {
    borderRadius: 46 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ConditionReportForm = () =>{
    const classes = useStyles();
    const [ source, setSource]  = useState([]);
    const [ open, setOpen ] = React.useState(false);
    const [ display, setDisplay ] = useState([])
    const [ damageType, setDamageType ] = useState([]);
    const [comments, setComments] = useState([]);
    const [ damageDimentions, setDamageDimentions ] = useState([]);
    const [ files, setFiles ] = useState([]);
    const [ openCameras, setOpenCameras ] = useState(false);
    const [ cameraOne, setCameraOne ] = useState('');
    const [ cameraTwo, setCameraTwo ] = useState('');
    const [ cameraThree, setCameraThree ] = useState('');
    const [ cameraFour, setCameraFour ] = useState('');

    const [ checked, setChecked ] = useState(true);
    const [ vin, setVin ] = useState();
    const [ reg, setReg ] = useState();
    const [ make, setMake ] = useState();
    const [ model, setModel ] = useState();
    const [ classC, setClassC ] = useState();
    const [ rate, setRate ] = useState();
    const [ deposit, setDeposit ] = useState();
    const [ assigned_client, setAssigned_client] = useState();
    const [ assigned_to, setAssigned_to] = useState();
    const [ assigned_location, setAssigned_location ] = useState();
    const [ assigned_date, setAssigned_date ] = useState();
    const [ assigned_by, setAssigned_by ] = useState();
    const [ adblue, setAdblue ] = useState();
    const [ fuel, setFuel ] = useState();
    const [ clean, setClean ] = useState(['', '', ''])
    const [ account_no, setAccount_no ] = useState();
    const [ cameras, setCameras ] = useState([]);
    const [ liveTest, setLiveTest ] = useState();
    const [ cameraStatus1, setCameraStatus1 ] = useState();
    const [ cameraStatus2, setCameraStatus2 ] = useState();
    const [ cameraStatus3, setCameraStatus3 ] = useState();
    const [ cameraStatus4, setCameraStatus4 ] = useState();
    const [ cameraStatus5, setCameraStatus5 ] = useState();
    const [ onseShot, setOneShot ] = useState();
    const [ steps, setSteps ] = useState(1);
    const [ damage11, setDamage11] = useState(1);
    const [ damage12, setDamage12] = useState(1);
    const [ damage13, setDamage13] = useState(1);
    const [ damage14, setDamage14] = useState(1);
    const [ damage21, setDamage21] = useState(1);
    const [ damage22, setDamage22] = useState(1);
    const [ damage23, setDamage23] = useState(1);
    const [ damage24, setDamage24] = useState(1);
    const [ damage31, setDamage31] = useState(1);
    const [imageURL, setImageURL] = useState(null);
    const [fleetGuy, setFleetGuy ] = useState();
    const [cameracount, setCameracount] = useState(0);
    const [ flDepth, setFlDepth ] = useState();
    const [ frDepth, setFrDepth ] = useState();
    const [ rlDepth, setRlDepth ] = useState();
    const [ rrDepth, setRrDepth ] = useState();
    const [ spareD, setSpareD ] = useState();
    const [ interiorTimer, setInteriorTimer ] = useState(false);
    const [ status, setStatus ] = useState();
    const [optionsStatus, setOptionsStatus ] = useState([]);
    const [ noLast, setNoLast ] = useState(false);
    const [ notes, setNotes ] = useState()
    const [ fuelCapacity, setFuelCapacity ] = useState()
    const sigCanvas = useRef({});

    const name  = new Date().getTime();
    
    const handleClickOpen = () => {
        setSource([])
        setOpen(true);
        setDisplay([])
        //setDamageType([])
        setFiles([])
        //setDamageDimentions([])
    };
 useEffect(()=>{  
    for(let i = 0; i <= 73; i++){
      damageType[i] = undefined;
    }
    UserService.getStatusOn().then(response =>{
      console.log(response.data)
      const len = response.data.length
      for(let i = 0; i < len; i++){
        setOptionsStatus(oldOptionsStatus => [...oldOptionsStatus, {label: response.data[i]?.heading, value: response.data[i]?.heading}])
      }
    }).catch(err => console.log(err))
  },[]) 
    const handleClickOpenCameras = async () => {
      setOpenCameras(true);
      //axios.get('https://node.tambor.uk:9000/oneShot').then(res => {
      //console.log(res.status);
        //if(res.status == 200){
          //setTimeout(()=>{
           
          //, 500)
        //}
      //})
      
     axios.get('https://node.tambor.uk:9000/getFile1').then(response => {
                  console.log(response.data)
                  setCameraOne(response.data);
                  
      })
      axios.get('https://node.tambor.uk:9000/getFile2').then(response => {
        console.log(response.data)
        setCameraTwo(response.data);
      })
      axios.get('https://node.tambor.uk:9000/getFile3').then(response => {
        console.log(response.data)
        setCameraThree(response.data);
      })
      axios.get('https://node.tambor.uk:9000/getFile4').then(response => {
        console.log(response.data)
        setCameraFour(response.data);
      })

      if(cameraOne){
        
      }
      /*axios.get('https://node.tambor.uk:9000/getLive').then(response => {
                console.log(response.data)
                setLiveTest(response.data)
      })*/
      }
    
    const handleCloseCameras = () =>{
      setOpenCameras(false)
      setCameraOne('')
      setCameraTwo('')
      setCameraThree('')
      setCameraFour('')
    }
  
   useEffect(()=>{
        axios.get('https://node.tambor.uk:9000/connectCamera1').then(res => {
           
            if(res.status == 200){
              setCameraStatus1(res.data.camera1);
            }else{
              setCameraStatus1('offline');
            }
        })
        axios.get('https://node.tambor.uk:9000/connectCamera2').then(res => {
         
          if(res.status == 200){
            setCameraStatus2(res.data.camera2);
          }else{
            setCameraStatus2('offline');
          }
        })
        axios.get('https://node.tambor.uk:9000/connectCamera3').then(res => {
          
          if(res.status == 200){
            setCameraStatus3(res.data.camera3);
          }else{
            setCameraStatus3('offline');
          }
        })
        axios.get('https://node.tambor.uk:9000/connectCamera4').then(res => {
       
          if(res.status == 200){
            setCameraStatus4(res.data.camera4);
          }else{
            setCameraStatus4('offline');
          }
        })
        axios.get('https://node.tambor.uk:9000/connectCamera5').then(res => {
       
          if(res.status == 200){
            setCameraStatus5(res.data.camera5);
          }else{
            setCameraStatus5('offline');
          }
        })
        
   },[])

        useEffect(()=>{
            axios.get('https://node.tambor.uk:9000/format').then(response => {
                console.log(response.status)
                
            })
            axios.get('https://node.tambor.uk:9000/stop').then(res => console.log(res))
            axios.get('https://node.tambor.uk:9000/start').then(res => console.log(res))
            /*axios.get('https://node.tambor.uk:9000/liveview').then(response => {
              console.log(response.status)
              
          })*/
        },[])
    let element = [];
    let interval = ';'
    async function intervalSS(){
      setCameracount(cameracount+1)
        await axios.get('https://node.tambor.uk:9000/getFile1').then(response => {
                   console.log(response.data)
                    setCameraOne(response.data);   
        }).catch(err => console.log(err))
        await axios.get('https://node.tambor.uk:9000/getFile2').then(response => {
          console.log(response.data)
            setCameraTwo(response.data);
        }).catch(err => console.log(err))
        await axios.get('https://node.tambor.uk:9000/getFile3').then(response => {
          console.log(response.data)
          setCameraThree(response.data);
        }).catch(err => console.log(err))
        await axios.get('https://node.tambor.uk:9000/getFile4').then(response => {
          console.log(response.data)
          setCameraFour(response.data);
        }).catch(err => console.log(err))
    }
  /*useEffect( ()=>{
       setInterval( intervalSS ,5000)

    },[])*/
    /*useEffect(()=>{
      UserService.startCameraInterval().catch(err=> console.log(err))
    },[])
    useEffect(()=>{
      UserService.startCameraInterval().catch(err=> console.log(err))
    },[])*/
    const handleClose = () => {
        setOpen(false);
        console.log(damageType)
        console.log(files)
    }
   
    let testObject = [];
    const handleCapture = (target) => {
        setFiles(oldFiles =>[...oldFiles, target.files[0]])

        if (target.files) {
            if (target.files.length !== 0) {
            const file = target.files[0];
            const newUrl = URL.createObjectURL(file);
            console.log(target.files[0])
            setSource(oldSource =>[...oldSource, newUrl]);
            }
        }
    };

    const handleBox = (index) =>{
        console.log(index)
        /*setDisplay(oldDisplay => {
            return oldDisplay.filter(element => element !== oldDisplay[index])
        })*/
        //delete damageType[index]
        let damageLet = damageType.filter((damage, indexDamage) =>{
          console.log(indexDamage)
          return index !== indexDamage
        })
        
       setDamageType(damageLet);
      
        console.log(damageType)
        /*setDamageType(damageType.forEach((element, index)=>{
          console.log(element);
          if(element){
            return element
          }
        }))*/
        setFiles(files.filter((file, indexfile) =>{
          return index !== indexfile
        }))
       setSource(source.filter((ele, indexSource)=>{
        return index !== indexSource
       }))
        setDamageDimentions(damageDimentions.filter((ele, damageIndex) =>{
          return index !== damageIndex
        }))
        /*let element = document.getElementById(index)
        if(display.length > 0){
            element.remove();
            console.log(display);
        }*/
        console.log(files)
        console.log(damageType)
    }
    
    useEffect(()=>{
        setDisplay([])
        //setDamageType([]);
        source.forEach((link, index) => {
          
            let element = <div className="flex" id={index}><div><Box display="flex" justifyContent="center" border={1} marginLeft={300} className={classes.imgBox}>
                                <img src={link} alt={"snap"} className={classes.img}></img>
                                
                            </Box>
                            <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[index] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    //value={this.state.defaultValueClass}
                                /> 
                                <div id={'element-'+index}>
                                  {damageType.length !== 0 && damageType[index] == 'Missing Parts'?'':<Select 
                                      options={damageType[index] == 'Missing Parts'? '': optionsNopa}
                                      styles={colourStylesS}
                                      isSearchable={ false }
                                      onChange={(e) => handleDamageDimention(e, index)}
                                      //value={this.state.defaultValueClass}
                                    />}
                                </div> 
                            </div><IconButton
                                    className="buttonDelete"
                                    onClick={e => handleBox(index)} ><DeleteForeverIcon  sx={{fontSize: 80, color: 'rgb(214, 109, 109)'}}/>
                                    </IconButton></div>
            setDisplay(oldDisplay =>[...oldDisplay, element])
                
         
        })
        
    },[source !== null, source, damageType])

  const handleDamageDimention = (e, index) =>{
    damageDimentions[index] = e.value
    
  }
  const handleSubmit = () =>{
    const formData = new FormData();
    for(let i = 0; i < files.length; i++){
      formData.append(files[i].name, files[i]);
    }
    
    formData.append('damageType', damageType);
    formData.append('damageDimentions', damageDimentions);
    formData.append('name', name);

    axios.post('https://node.tambor.uk:9000/image', formData).then(res => {
      console.log(res);
    })
  } 

  const [submitProgress, setSubmitProgress] = useState(0);
  const [ subStuck, setSubmitStuck ] = useState(false);
  const handleSubmitCameras = () =>{
    setSubmitStuck(true);
    axios.get('https://node.tambor.uk:9000/stop').then(res => console.log(res))
    let fileName = reg+'-'+new Date().getTime();
    let dataSend = {vin_no: vin, vehicle_reg: reg, vehicle_make: make, vehicle_model: model, vehicle_class: classC, last_client: assigned_to, adblue_liters: adblue, file: 'https://node.tambor.uk:9000.27:8080/public/uploads/cr/'+fileName+'.pdf', cleanliness_in: clean, notes: notes, fuel_capacity: fuelCapacity}
    let picturesData = {file: fileName, damages: damageType, dimentions: damageDimentions, reg: reg, vin: vin, make: make, model: model, class: classC, assigned_to: assigned_to, interior_count: countShoots, comments: comments, adblue: adblue, clean: clean, fuel: fuel, account_no: account_no, rate: rate, deposit: deposit, assigned_client: assigned_client, assigned_location: assigned_location, assigned_date: assigned_date, assigned_by: assigned_by, signature: imageURL, fleetGuy: fleetGuy, flDepth: flDepth, frDepth: frDepth, rlDepth: rlDepth, rrDepth: rrDepth, spDepth: spareD, status: status, noLast: noLast, fuel_capacity: fuelCapacity}
    UserService.insertConditionLog(dataSend)
            .then(response => {
                //this.setState({checkSubmit: true}) 
                console.log(response)
            }).catch(err => {
                //this.setState({checkSubmit: false})
                console.log(err)
            })
    setSubmitProgress(3)
    axios.post('https://node.tambor.uk:9000/picturesToPdf', picturesData).then(res => {
      
      if(res.status == 200){
         setSubmitProgress(1)
      }else{
        setSubmitProgress(2)
      }
    })        
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(checked);
   };

   const handleNoLastDriver = (event) => {
    setNoLast(event.target.checked);
    console.log(noLast);
   }; 
  const handleVin = (e) => {
    if(e.target.value.length < 17){
      
      setVin(e.target.value)
      console.log(vin)
    }else if(e.target.value.length == 17){
      setVin(e.target.value)
      console.log(e.target.value)
      let  dataSend = {vin: e.target.value}
      console.log(dataSend);
      UserService.conditionAsset(dataSend)
      .then(response => {
          
          console.log(response)
          setNotes(response.data.notes)
          setReg(response.data.reg)
          setMake(response.data.make)
          setModel(response.data.model)
          setClassC(response.data.class)
          setRate(response.data.rate)
          setDeposit(response.data.deposit)
          setAssigned_client(response.data.assigned_client)
          setAssigned_to(response.data.assigned_to)
          setAssigned_location(response.data.assigned_location)
          setAssigned_date(response.data.assigned_date)
          setAssigned_by(response.data.assigned_by)
          setFuelCapacity(response.data.fuel_capacity)
          UserService.getAccount({name: response.data.assigned_to}).then(response => setAccount_no(response.data.account_no)).catch(err=> console.log(err))
      }).catch(err => {
        
          console.log(err)
      })
    }
  }
  useEffect(()=>{
    if(checked == false){
      setVin('')
      setReg('')
      setMake('')
      setModel('')
      setClassC('')
      setRate('')
      setDeposit('')
      setAssigned_client('')
      setAssigned_to('')
      setAssigned_location('')
      setAssigned_date('')
      setAssigned_by('')
      setTimeout(()=>{
        setChecked(true)
      },1000)
      
    }
  },[checked])

  function valuetext(value) {
    return `${value}%`;
  }
  const handleClean1 = (e) =>{
    setClean([e.target.checked, clean[1], clean[2]])
  }
  const handleClean2 = (e) =>{
    setClean([clean[0], e.target.checked, clean[2]])
  }
  const handleClean3 = (e) =>{
    setClean([clean[0], clean[1], e.target.checked])
    console.log(clean)
  }
  const handleNext = () =>{
    //setShootCheck(false)
    //setShootStyle()
    setSteps(steps+1)
    /*axios.get('https://node.tambor.uk:9000/getFile1').then(response => {
                  console.log(response.data)
                  setCameraOne(response.data);
                  
      })
      axios.get('https://node.tambor.uk:9000/getFile2').then(response => {
        console.log(response.data)
        setCameraTwo(response.data);
      })
      axios.get('https://node.tambor.uk:9000/getFile3').then(response => {
        console.log(response.data)
        setCameraThree(response.data);
      })
      axios.get('https://node.tambor.uk:9000/getFile4').then(response => {
        console.log(response.data)
        setCameraFour(response.data);
      })*/
  } 
  const handlePrevious = () =>{
    setSteps(steps-1)
  }
  const handleDamage11 = () =>{
    setDamage11(damage11+1)
  }
  const handleDamage111 = () =>{
    setDamage11(damage11-1)
  }
  const handleDamage1111 = () =>{
    setDamage11(damage11-2)
  }
  const handleDamage12 = () =>{
    setDamage12(damage12+1)
  }
  const handleDamage122 = () =>{
    setDamage12(damage12-1)
  }
  const handleDamage1222 = () =>{
    setDamage12(damage12-2)
  }
  const handleDamage13 = () =>{
    setDamage13(damage13+1)
  }
  const handleDamage133 = () =>{
    setDamage13(damage13-1)
  }
  const handleDamage1333 = () =>{
    setDamage13(damage13-2)
  }
  const handleDamage14 = () =>{
    setDamage14(damage14+1)
  }
  const handleDamage144 = () =>{
    setDamage14(damage14-1)
  }
  const handleDamage1444 = () =>{
    setDamage14(damage14-2)
  }
  const handleDamage21 = () =>{
    setDamage21(damage21+1)
  }
  const handleDamage211 = () =>{
    setDamage21(damage21-1)
  }
  const handleDamage2111 = () =>{
    setDamage21(damage21-2)
  }
  const handleDamage22 = () =>{
    setDamage22(damage22+1)
  }
  const handleDamage222 = () =>{
    setDamage22(damage22-1)
  }
  const handleDamage2222 = () =>{
    setDamage22(damage22-2)
  }
  const handleDamage23 = () =>{
    setDamage23(damage23+1)
  }
  const handleDamage233 = () =>{
    setDamage23(damage23-1)
  }
  const handleDamage2333 = () =>{
    setDamage23(damage23-2)
  }
  const handleDamage24 = () =>{
    setDamage24(damage24+1)
  }
  const handleDamage244 = () =>{
    setDamage24(damage24-1)
  }
  const handleDamage2444 = () =>{
    setDamage24(damage24-2)
  }
  const handleDamage31 = () =>{
    setDamage31(damage31+1)
  }
  const handleDamage311 = () =>{
    setDamage31(damage31-1)
  }
  const handleDamage3111 = () =>{
    setDamage31(damage31-2)
  }
  function handleConnection(camera){
    console.log(camera);
    if(camera == '0'){
      axios.get('https://node.tambor.uk:9000/connectCamera1').then(res => {
                            console.log(res);
                            if(res.status == 200){
                              setCameraStatus1(res.data.camera1);
                            }else{
                              setCameraStatus1('offline');
                            }
                           })
    }
    if(camera == '1'){
        axios.get('https://node.tambor.uk:9000/connectCamera2').then(res => {
          console.log(res);
          if(res.status == 200){
            setCameraStatus2(res.data.camera2);
          }else{
            setCameraStatus2('offline');
          }
        })
    }
    if(camera == '2'){
        axios.get('https://node.tambor.uk:9000/connectCamera3').then(res => {
          console.log(res);
          if(res.status == 200){
            setCameraStatus3(res.data.camera3);
          }else{
            setCameraStatus3('offline');
          }
        })
    }
    if(camera == '3'){
        axios.get('https://node.tambor.uk:9000/connectCamera4').then(res => {
          console.log(res);
          if(res.status == 200){
            setCameraStatus4(res.data.camera4);
          }else{
            setCameraStatus4('offline');
          }
        })
      }
      if(camera == '4'){
        axios.get('https://node.tambor.uk:9000/connectCamera5').then(res => {
          console.log(res);
          if(res.status == 200){
            setCameraStatus5(res.data.camera5);
          }else{
            setCameraStatus5('offline');
          }
        })
      }
  }
  const [shootCheck, setShootCheck] = useState(false);
  const [shootStyle, setShootStyle] = useState(); 
  const handleShoot = () =>{
    if(shootCheck == false){
      axios.get('https://node.tambor.uk:9000/oneShot').then(response => {
          if(response.status == '200'){
            setShootCheck(true)
            setShootStyle({backgroundColor: 'gray !important'})
          }
      })
    }
  }
  const [shootCheck1, setShootCheck1] = useState(false);
  const [shootStyle1, setShootStyle1] = useState(); 
  const handleShoot1 = () =>{
    if(shootCheck1 == false){
      axios.get('https://node.tambor.uk:9000/oneShot').then(response => {
  
          if(response.status == '200'){
            setShootCheck1(true)
            setShootStyle1({backgroundColor: 'gray !important'})
          }
      })
    }
  }
  const [countShoots, setCountShoots] = useState(0);
  const handleShootInterior = () =>{
    setInteriorTimer(true)
    setTimeout(()=>{
      setInteriorTimer(false)
    }, 5000)
    if(countShoots < 10){
      axios.get('https://node.tambor.uk:9000/oneShot5').then(response => {
        
          if(response.status == '200'){
            setCountShoots(countShoots+1)
            //setShootCheck(true)
            //setShootStyle({backgroundColor: 'gray !important'})
          }
      })
    }
  }

  
  const clear = () => sigCanvas.current.clear();
  const [stuck, setStuck] = useState(false)
  const save = () => {
    setOpen(false);
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    if(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") !== 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' && fleetGuy){
      setStuck(true)
    }
  };

  /*useEffect(() => {
    window.onbeforeunload = function() {
      
    };

    return () => {
        window.onbeforeunload = null;
    };
}, []);

useEffect(() => {
  window.addEventListener("beforeunload", alertUser);
  return () => {
    window.removeEventListener("beforeunload", alertUser);
  };
}, []);async
const alertUser = (e) => {
  e.preventDefault();
  //e.returnValue = "";
  axios.get('https://node.tambor.uk:9000/start').then(res => console.log(res))
};*/
const handleFlDepth = (e) =>{
  setFlDepth(e.target.value)
}
const handleFrDepth = (e) =>{
  setFrDepth(e.target.value)
}
const handleRlDepth = (e) =>{
  setRlDepth(e.target.value)
}
const handleRrDepth = (e) =>{
  setRrDepth(e.target.value)
}
const handleSpDepth = (e) =>{
  setSpareD(e.target.value)
}
  return (
    <div>
      {steps == 1 ?
      <div className="containerf containerfCorrection">
          <header className="jumbotronl">
              <h1 className="collectionTitle">Condition Report</h1>
          </header>
          <h4 className="subttt">Vehicle Details</h4>
          <div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">Vin Number <span className="req">(Require)</span></label>
                    <input type="text" name='reg' className="twoIn2" value={vin?.toUpperCase()}  onChange={e => handleVin(e)} />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Vehicle Reg <span className="req">(Require)</span></label>
                    <input type="text" name='deposit' className="twoIn2" value={reg} /*onChange={e => handleDeposit(e)}*/  />
                </div>
            </div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">Make <span className="req">(Require)</span></label>
                    <input type="text" name='reg' className="twoIn2" value={make} /*onChange={e => handleRate(e)}*/  />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Model <span className="req">(Require)</span></label>
                    <input type="text" name='deposit' className="twoIn2" value={model} /*onChange={e => handleDeposit(e)}*/  />
                </div>
            </div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">Class <span className="req">(Require)</span></label>
                    <input type="text" name='reg' className="twoIn2" value={classC} /*onChange={e => handleRate(e)}*/  />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">£Rate <span className="req">(Require)</span></label>
                    <input type="number" name='deposit' className="twoIn2" value={rate} /*onChange={e => handleDeposit(e)}*/  />
                </div>
            </div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">£Deposit <span className="req">(Require)</span></label>
                    <input type="number" name='reg' className="twoIn2" value={deposit} /*onChange={e => handleRate(e)}*/  />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Assigned Client <span className="req">(Require)</span></label>
                    <input type="text" name='deposit' className="twoIn2" value={assigned_client} /*onChange={e => handleDeposit(e)}*/  />
                </div>
            </div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">Assigned To <span className="req">(Require)</span></label>
                    <input type="text" name='reg' className="twoIn2" value={assigned_to} /*onChange={e => handleRate(e)}*/  />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Assigned Location <span className="req">(Require)</span></label>
                    <input type="text" name='deposit' className="twoIn2" value={assigned_location} /*onChange={e => handleDeposit(e)}*/  />
                </div>
            </div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">Assigned Date <span className="req">(Require)</span></label>
                    <input type="text" name='reg' className="twoIn2" value={assigned_date} /*onChange={e => handleRate(e)}*/  />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Assigned By <span className="req">(Require)</span></label>
                    <input type="text" name='deposit' className="twoIn2" value={assigned_by} /*onChange={e => handleDeposit(e)}*/  />
                </div>
            </div>
            <div className="toggle">
              <div className="inlineLnI">
                  <label>Are all the detail above correct?</label>
                  <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                      <IOSSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{height: 45}} onChange={handleChange} checked={checked}/>
                    <Typography>Yes</Typography>
                    {checked? '': <span className="req"> Wrong vin number scanned</span>}
                  </Stack>
              </div>
            </div>
          </div>
          <h4 className="subttt">Vehicle State</h4>
          <div className="inlineLnx">
              <label for='reg' className="eCorrection">Adblue Added <span className="req">(Require)</span></label>
              <input type="number" name='reg' className="twoIn2" onChange={e => setAdblue(e.target.value)} />
          </div>
          <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='frDepth' className="eCorrection">Front/Rigth Tyre Depth <span className="req">(Require)</span></label>
                    <input type="number" name='frDepth' className="twoIn2" placeholder="depth in mm" /*value={assigned_date}*/ onChange={e => handleFrDepth(e)}  />
                </div>
                <div className="inlineLnI">
                    <label for='flDepth' className="eCorrection">Front/Left Tyre Depth <span className="req">(Require)</span></label>
                    <input type="number" name='flDepth' className="twoIn2" placeholder="depth in mm"/*value={assigned_by}*/ onChange={e => handleFlDepth(e)}  />
                </div>
            </div>
          <div className="flexAssets">
              <div className="inlineLnI">
                  <label for='rlDepth' className="eCorrection">Rear/Left Tyre Depth <span className="req">(Require)</span></label>
                  <input type="number" name='rlDepth' className="twoIn2" placeholder="depth in mm"/*value={assigned_date}*/ onChange={e => handleRlDepth(e)}  />
              </div>
              <div className="inlineLnI">
                  <label for='rrDepth' className="eCorrection">Rear/Right Tyre Depth <span className="req">(Require)</span></label>
                  <input type="number" name='rrDepth' className="twoIn2" placeholder="depth in mm"/*value={assigned_by}*/ onChange={e => handleRrDepth(e)}  />
              </div>
              <div className="inlineLnI">
                  <label for='spare' className="eCorrection">Spare Tyre Depth <span className="req">(Require)</span></label>
                  <input type="number" name='spare' className="twoIn2" placeholder="depth in mm"/*value={assigned_by}*/ onChange={e => handleSpDepth(e)}  />
              </div>
          </div>  
          <div className="flexAssets">
                <div className="inlineLnI">
                    <label for='reg' className="eCorrection">Fuel Level <span className="req">(Require)</span></label>
                    <Slider
                      aria-label="Custom marks"
                      defaultValue={0}
                      getAriaValueText={valuetext}
                      step={1}
                      valueLabelDisplay={valuetext}
                      onChange={e => setFuel(e.target.value)}
                      marks={marks}
                    />
                </div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Vehicle Cleanliness <span className="req">(Require)</span></label>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      <FormControlLabel
                        label="Outside not clean"
                        control={<Checkbox checked={clean[0]} onChange={handleClean1} />}
                      />
                      <FormControlLabel
                        label="Inside not clean"
                        control={<Checkbox checked={clean[1]} onChange={handleClean2} />}
                      />
                       <FormControlLabel
                        label="Cargo area not clean"
                        control={<Checkbox checked={clean[2]} onChange={handleClean3} />}
                      />
                    </Box>
                </div>
            </div>
            <div className="inlineLnx">
                <label for='reg' className="eCorrection">Vehicle Status<span className="req">(Require)</span></label>
                <Select 
                    options={optionsStatus}
                    styles={colourStylesS}
                    isSearchable={ false }
                    onChange={e => setStatus(e.value)}
                    value={{label: status || 'None', value: status || ''}}
                />
            </div>
            <div className="toggle">
              <div className="inlineLnI">
                    <label>Add last driver details?</label>
                    <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>No</Typography>
                        <IOSSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{height: 45}} onChange={handleNoLastDriver} checked={noLast}/>
                      <Typography>Yes</Typography>
                      {checked? '': <span className="req"> Wrong vin number scanned</span>}
                    </Stack>
              </div>
            </div>
            <div className="inlineLnx">
              <label for='notes' className="eCorrection">Notes </label>
              <input type="text" name='notes' className="twoIn2" onChange={e => setNotes(e.target.value)} value={notes}/>
          </div>
            {vin ? <button className="buttonY" onClick={handleNext}>Next <ArrowForwardIosIcon/></button> : <button className="buttonY" style={{backgroundColor: 'gray'}}>Next <ArrowForwardIosIcon/></button>}
      </div> : steps == 2?
        <div className="containerf correctionCamera">
          <h2 className="title">Front and Right</h2>
        <div className="flex">
              <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                cameraStatus1 == 'on' ? 
                <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 1 Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                       // component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('1')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 1 Offline</h3>
                    {/*<input
                        //accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="button"
                        capture="environment"
                        onClick={(e) => {
                          handleConnection(e, 0)
                        }}
                      />*/}
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('0')} color="primary" />
                        </IconButton>
                    </label>
                  </div>
                </div>
                }
                {/*cameraOne ?*/ damage11 == 1 ? /*<img src={cameraOne} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}} alt={"snap"} className={classes.img}></img>*/
                <LiveViewer link={cameraOne} camera={1}/>
                
                : 
                damage11 == 2?
                  <div>
                    <div className="selectDamage">
                      <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[0] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[0] || 'None', value: damageType[0] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[0] !== 'Missing Parts' || damageType[0]== 'None' ?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 0)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                      <h4 className="titleDamage">Damage 2</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[1] = e.value;
                                    setDamageType(placeHld)
                                 
                                  }}
                                  value={{label: damageType[1] || 'None', value: damageType[1] || 'None'}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[1] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 1)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                      <h4 className="titleDamage">Damage 3</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[2] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[2] || 'None', value: damageType[2] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[2] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 2)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                      <h4 className="titleDamage">Damage 4</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[3] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[3] || 'None', value: damageType[3] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[3] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 3)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[4] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[4] || 'None', value: damageType[4] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[4] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 4)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[5] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[5] || 'None', value: damageType[5] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[5] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 5)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[6] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[6] || 'None', value: damageType[6] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[6] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 6)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[7] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[7] || 'None', value: damageType[7] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[7] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 7)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    console.log(e.target.value)
                    const placeHold = [...comments];
                    placeHold[0] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div>
                
                /*: <CircularProgress/>*/}
              </CardContent>
            <CardActions>
            {damage11 == 1? <button className="button" onClick={handleDamage11}>Mark Damage</button>: damage11 == 2? <><button className="button" onClick={handleDamage111}>Ok</button><button className="button" onClick={handleDamage11}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage1111}>Ok</button>}
            </CardActions>
          </Card>
          
              
          <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus2 == 'on' ?
                  <div className="online">
                    <div className="flex">
                      <h3 className="cameraTitle">Camera 2 Online</h3>
                      {/*<input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="file"
                          capture="environment"
                          onChange={(e) => handleCapture(e.target)}
                        />
                      <label htmlFor="icon-button-file">
                          <IconButton
                          color="primary"
                          aria-label="upload picture"
                          //component="span"
                          >
                          <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('2')} color="primary" />
                          </IconButton>
                      </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                    <div className="flex">
                      <h3 className="cameraTitle">Camera 2 Offline</h3>
                      {/*<input
                          //accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="button"
                          //capture="environment"
                          onClick={e => handleConnection(e, 1)}
                      />*/}
                      <label htmlFor="icon-button-file">
                          <IconButton
                          color="primary"
                          aria-label="upload picture"
                          //component="span"
                          >
                              <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('1')} color="primary" />
                          </IconButton>

                      </label>
                    </div>
                  </div>
                }

                {/*cameraTwo ?*/ damage12 == 1 ? /*<img src={cameraTwo} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img>*/
                <LiveViewer link={cameraTwo} camera={2}/>
                : damage12 == 2 ?
                <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[8] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[8] || 'None', value: damageType[8] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[8] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 8)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[9] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[9] || 'None', value: damageType[9] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[9] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 9)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                          </div>
                          <h4 className="titleDamage">Damage 3</h4>
                          <div className="flexAssets">
                                <div className="inlineLnI">
                                        <Select 
                                      options={optionsDamage}
                                      styles={colourStylesS}
                                      isSearchable={ false }
                                      onChange={(e) => {
                                        const placeHld = [...damageType];
                                        placeHld[10] = e.value;
                                        setDamageType(placeHld)
                                        console.log(files)
                                      }}
                                      value={{label: damageType[10] || 'None', value: damageType[10] || ''}}
                                  />
                                </div>
                                <div className="inlineLnI">
                                {damageType[10] !== 'Missing Parts'?
                                <div id={'element'}>
                                  <Select 
                                      options={optionsNopa}
                                      styles={colourStylesS}
                                      isSearchable={ false }
                                      onChange={(e) => handleDamageDimention(e, 10)}
                                      //value={this.state.valueClass}
                                    />
                                  </div>: ''}
                                </div>
                            </div>
                            <h4 className="titleDamage">Damage 4</h4>
                            <div className="flexAssets">
                                  <div className="inlineLnI">
                                          <Select 
                                        options={optionsDamage}
                                        styles={colourStylesS}
                                        isSearchable={ false }
                                        onChange={(e) => {
                                          const placeHld = [...damageType];
                                          placeHld[11] = e.value;
                                          setDamageType(placeHld)
                                          console.log(files)
                                        }}
                                        value={{label: damageType[11] || 'None', value: damageType[11] || ''}}
                                    />
                                  </div>
                                  <div className="inlineLnI">
                                  {damageType[11] !== 'Missing Parts'?
                                  <div id={'element'}>
                                    <Select 
                                        options={optionsNopa}
                                        styles={colourStylesS}
                                        isSearchable={ false }
                                        onChange={(e) => handleDamageDimention(e, 11)}
                                        //value={this.state.valueClass}
                                      />
                                    </div>: ''}
                                  </div>
                              </div>
                              <h4 className="titleDamage">Damage 5</h4>
                            <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[12] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[12] || 'None', value: damageType[12] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[12] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 12)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[13] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[13] || 'None', value: damageType[13] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[13] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 13)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[14] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[14] || 'None', value: damageType[14] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[14] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 14)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[15] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[15] || 'None', value: damageType[15] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[15] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 15)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[1] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div>
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
              {damage12 == 1? <button className="button" onClick={handleDamage12}>Mark Damage</button>: damage12 == 2? <><button className="button" onClick={handleDamage122}>Ok</button><button className="button" onClick={handleDamage12}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage1222}>Ok</button>}

            </CardActions>
          </Card>
        </div>
        <div className="flex">
        <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus3 == 'on' ?
                  <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 3 Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('3')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 3 Offline</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleConnection(e, 2)}
                      />*/}
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('2')} color="primary" />
                        </IconButton>

                    </label>
                  </div>
                </div>
                }
                {/*cameraThree ?*/ damage13 == 1 ? /*<img src={cameraThree} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img>*/ 
                <LiveViewer link={cameraThree} camera={3}/>
                :damage13 == 2?
                  <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[16] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[16] || 'None', value: damageType[16] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[16] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 16)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[17] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[17] || 'None', value: damageType[17] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[17] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 17)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 3</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[18] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[18] || 'None', value: damageType[18] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[18] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 18)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 4</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[19] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[19] || 'None', value: damageType[19] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[19] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 19)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[20] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[20] || 'None', value: damageType[20] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[20] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 20)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[21] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[21] || 'None', value: damageType[21] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[21] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 21)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[22] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[22] || 'None', value: damageType[22] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[22] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 22)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[23] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[23] || 'None', value: damageType[23] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[23] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 23)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[2] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div>  
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
              {damage13 == 1? <button className="button" onClick={handleDamage13}>Mark Damage</button>: damage13 == 2? <><button className="button" onClick={handleDamage133}>Ok</button><button className="button" onClick={handleDamage13}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage1333}>Ok</button>}

            </CardActions>
          </Card>
          
              
          <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus4 == 'on' ?
                  <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 4 Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                       // component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('4')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 4 Offline</h3>
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('3')} color="primary" />
                        </IconButton>

                    </label>
                  </div>
                </div>
                }
                {/*cameraFour ?*/ damage14 == 1 ? /*<img src={cameraFour} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img> */
                <LiveViewer link={cameraFour} camera={4}/>
                : damage14 == 2?
                  <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[24] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[24] || 'None', value: damageType[24] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[24] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[25] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[25] || 'None', value: damageType[25] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[25] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 3</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[26] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[26] || 'None', value: damageType[26] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[26] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 4</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[27] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[27] || 'None', value: damageType[27] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[27] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[28] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[28] || 'None', value: damageType[28] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[28] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[29] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[29] || 'None', value: damageType[29] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[29] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[30] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[30] || 'None', value: damageType[30] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[30] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[31] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[31] || 'None', value: damageType[31] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[31] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    //onChange={(e) => handleDamageDimention(e, index)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[3] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div> 
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
            {damage14 == 1? <button className="button" onClick={handleDamage14}>Mark Damage</button>: damage14 == 2? <><button className="button" onClick={handleDamage144}>Ok</button><button className="button" onClick={handleDamage14}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage1444}>Ok</button>}

            </CardActions>
          </Card>
          
        </div>
        <div className="flex">
        {shootStyle ?<div className="flexCam"> <button className="button shoot" onClick={handleShoot} style={{backgroundColor:'gray'}}><CameraIcon sx={{fontSize: 30}}/>Shoot</button><h3 className="fnr">Front and Right done</h3> </div>:<button className="button shoot" onClick={handleShoot} ><CameraIcon sx={{fontSize: 30}}/>Shoot</button>}
          {!shootStyle ? <div className="between2"></div>:''}
          {!shootCheck ? <button className="buttonY" onClick={handlePrevious}><ArrowBackIosNewIcon/>Previous</button> : <button className="buttonY" style={{backgroundColor: 'gray'}}><ArrowBackIosNewIcon/>Previous</button>}{shootCheck ? <button className="buttonY space" onClick={handleNext}>Next <ArrowForwardIosIcon/></button> : <button className="buttonY space" style={{backgroundColor: 'gray'}}>Next <ArrowForwardIosIcon/></button>}
        </div>
    </div> :steps == 3?
        <div className="containerf correctionCamera" >
          <h2 className="title">Rear and Left</h2>
        <div className="flex">
              <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                cameraStatus1 == 'on' ? 
                <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 1 Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                       // component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('1')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 1 Offline</h3>
                    {/*<input
                        //accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="button"
                        capture="environment"
                        onClick={(e) => {
                          handleConnection(e, 0)
                        }}
                      />*/}
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('0')} color="primary" />
                        </IconButton>
                    </label>
                  </div>
                </div>
                }
                {/*cameraOne ?*/ damage21 == 1 ? /*<img src={cameraOne} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}} alt={"snap"} className={classes.img}></img>*/
                <LiveViewer link={cameraOne} camera={1}/>
                
                : 
                damage21 == 2?
                  <div>
                    <div className="selectDamage">
                      <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[32] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[32] || 'None', value: damageType[32] || 'None'}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[32] !== 'Missing Parts' || damageType[32]== 'None' ?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 32)}
                                  //value={damageType[32]}
                                />
                              </div>: ''}
                            </div>
                        </div>
                      <h4 className="titleDamage">Damage 2</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[33] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[33] || 'None', value: damageType[33] || 'None'}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[33] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 33)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                      <h4 className="titleDamage">Damage 3</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[34] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[34] || 'None', value: damageType[34] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[34] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 34)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                      <h4 className="titleDamage">Damage 4</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[35] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[35] || 'None', value: damageType[35] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[35] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 35)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[36] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[36] || 'None', value: damageType[36] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[36] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 36)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[37] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[37] || 'None', value: damageType[37] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[37] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 37)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[38] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[38] || 'None', value: damageType[38] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[38] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 38)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[39] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[39] || 'None', value: damageType[39] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[39] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 39)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[4] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div>
                
                /*: <CircularProgress/>*/}
              </CardContent>
            <CardActions>
            {damage21 == 1? <button className="button" onClick={handleDamage21}>Mark Damage</button>: damage21 == 2? <><button className="button" onClick={handleDamage211}>Ok</button><button className="button" onClick={handleDamage21}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage2111}>Ok</button>}
            </CardActions>
          </Card>
          
              
          <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus2 == 'on' ?
                  <div className="online">
                    <div className="flex">
                      <h3 className="cameraTitle">Camera 2 Online</h3>
                      {/*<input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="file"
                          capture="environment"
                          onChange={(e) => handleCapture(e.target)}
                        />
                      <label htmlFor="icon-button-file">
                          <IconButton
                          color="primary"
                          aria-label="upload picture"
                          //component="span"
                          >
                          <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('2')} color="primary" />
                          </IconButton>
                      </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                    <div className="flex">
                      <h3 className="cameraTitle">Camera 2 Offline</h3>
                      {/*<input
                          //accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="button"
                          //capture="environment"
                          onClick={e => handleConnection(e, 1)}
                      />*/}
                      <label htmlFor="icon-button-file">
                          <IconButton
                          color="primary"
                          aria-label="upload picture"
                          //component="span"
                          >
                              <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('1')} color="primary" />
                          </IconButton>

                      </label>
                    </div>
                  </div>
                }

                {/*cameraTwo ?*/ damage22 == 1 ? /*<img src={cameraTwo} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img>*/
                <LiveViewer link={cameraTwo} camera={2}/>
                : damage22 == 2 ?
                <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                      <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[40] = e.value;
                                    setDamageType(placeHld)
                              
                                  }}
                                  value={{label: damageType[40] || 'None', value: damageType[40] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[40] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 40)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[41] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[41] || 'None', value: damageType[41] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[41] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 41)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                          </div>
                          <h4 className="titleDamage">Damage 3</h4>
                          <div className="flexAssets">
                                <div className="inlineLnI">
                                        <Select 
                                      options={optionsDamage}
                                      styles={colourStylesS}
                                      isSearchable={ false }
                                      onChange={(e) => {
                                        const placeHld = [...damageType];
                                        placeHld[42] = e.value;
                                        setDamageType(placeHld)
                                        console.log(files)
                                      }}
                                      value={{label: damageType[42] || 'None', value: damageType[42] || ''}}
                                  />
                                </div>
                                <div className="inlineLnI">
                                {damageType[42] !== 'Missing Parts'?
                                <div id={'element'}>
                                  <Select 
                                      options={optionsNopa}
                                      styles={colourStylesS}
                                      isSearchable={ false }
                                      onChange={(e) => handleDamageDimention(e, 42)}
                                      //value={this.state.valueClass}
                                    />
                                  </div>: ''}
                                </div>
                            </div>
                            <h4 className="titleDamage">Damage 4</h4>
                            <div className="flexAssets">
                                  <div className="inlineLnI">
                                          <Select 
                                        options={optionsDamage}
                                        styles={colourStylesS}
                                        isSearchable={ false }
                                        onChange={(e) => {
                                          const placeHld = [...damageType];
                                          placeHld[43] = e.value;
                                          setDamageType(placeHld)
                                          console.log(files)
                                        }}
                                        value={{label: damageType[43] || 'None', value: damageType[43] || ''}}
                                    />
                                  </div>
                                  <div className="inlineLnI">
                                  {damageType[43] !== 'Missing Parts'?
                                  <div id={'element'}>
                                    <Select 
                                        options={optionsNopa}
                                        styles={colourStylesS}
                                        isSearchable={ false }
                                        onChange={(e) => handleDamageDimention(e, 43)}
                                        //value={this.state.valueClass}
                                      />
                                    </div>: ''}
                                  </div>
                              </div>
                              <h4 className="titleDamage">Damage 5</h4>
                            <div className="flexAssets">
                            <div className="inlineLnI">
                                    <Select 
                                  options={optionsDamage}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => {
                                    const placeHld = [...damageType];
                                    placeHld[44] = e.value;
                                    setDamageType(placeHld)
                                    console.log(files)
                                  }}
                                  value={{label: damageType[44] || 'None', value: damageType[44] || ''}}
                              />
                            </div>
                            <div className="inlineLnI">
                            {damageType[44] !== 'Missing Parts'?
                            <div id={'element'}>
                              <Select 
                                  options={optionsNopa}
                                  styles={colourStylesS}
                                  isSearchable={ false }
                                  onChange={(e) => handleDamageDimention(e, 44)}
                                  //value={this.state.valueClass}
                                />
                              </div>: ''}
                            </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[45] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[45] || 'None', value: damageType[45] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[45] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 45)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[46] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[46] || 'None', value: damageType[46] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[46] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 46)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[47] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[47] || 'None', value: damageType[47] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[47] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 47)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[5] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div>
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
              {damage22 == 1? <button className="button" onClick={handleDamage22}>Mark Damage</button>: damage22 == 2? <><button className="button" onClick={handleDamage222}>Ok</button><button className="button" onClick={handleDamage22}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage2222}>Ok</button>}

            </CardActions>
          </Card>
        </div>
        <div className="flex">
        <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus3 == 'on' ?
                  <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 3 Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('3')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 3 Offline</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleConnection(e, 2)}
                      />*/}
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('2')} color="primary" />
                        </IconButton>

                    </label>
                  </div>
                </div>
                }
                {/*cameraThree ?*/ damage23 == 1 ? /*<img src={cameraThree} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img>*/ 
                <LiveViewer link={cameraThree} camera={3}/>
                :damage23 == 2?
                  <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[48] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[48] || 'None', value: damageType[48] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[48] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 48)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[49] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[49] || 'None', value: damageType[49] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[49] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 49)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 3</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[50] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[50] || 'None', value: damageType[50] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[50] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 50)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 4</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[51] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[51] || 'None', value: damageType[51] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[51] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 51)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[52] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[52] || 'None', value: damageType[52] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[52] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 52)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[53] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[53] || 'None', value: damageType[53] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[53] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 53)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[54] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[54] || 'None', value: damageType[54] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[54] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 54)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[55] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[55] || 'None', value: damageType[55] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[55] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 55)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[6] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div>  
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
              {damage23 == 1? <button className="button" onClick={handleDamage23}>Mark Damage</button>: damage23 == 2? <><button className="button" onClick={handleDamage233}>Ok</button><button className="button" onClick={handleDamage23}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage2333}>Ok</button>}

            </CardActions>
          </Card>
          
              
          <Card sx={{ maxWidth: 875, minHeight: 675, minWidth: 875 , marginLeft: 15, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus4 == 'on' ?
                  <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 4 Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                       // component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('4')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Camera 4 Offline</h3>
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('3')} color="primary" />
                        </IconButton>

                    </label>
                  </div>
                </div>
                }
                {/*cameraFour ?*/ damage24 == 1 ? /*<img src={cameraFour} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img> */
                <LiveViewer link={cameraFour} camera={4}/>
                : damage24 == 2?
                  <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[56] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[56] || 'None', value: damageType[56] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[56] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 56)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[57] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[57] || 'None', value: damageType[57] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[57] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 57)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 3</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[58] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[58] || 'None', value: damageType[58] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[58] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 58)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 4</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[59] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[59] || 'None', value: damageType[59] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[59] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 59)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[60] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[60] || 'None', value: damageType[60] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[60] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 60)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[61] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[61] || 'None', value: damageType[61] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[61] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 61)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[62] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[62] || 'None', value: damageType[62] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[62] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 62)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[63] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[63] || 'None', value: damageType[63] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[63] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 63)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[7] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div> 
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
            {damage24 == 1? <button className="button" onClick={handleDamage24}>Mark Damage</button>: damage24 == 2? <><button className="button" onClick={handleDamage244}>Ok</button><button className="button" onClick={handleDamage24}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage2444}>Ok</button>}

            </CardActions>
          </Card>
          
        </div>
        <div className="flex">
        {shootStyle1 ?<div className="flexCam"> <button className="button shoot" onClick={handleShoot1} style={{backgroundColor:'gray'}}><CameraIcon sx={{fontSize: 30}}/>Shoot</button> <h3 className="fnr">Rear and Left done</h3></div>:<button className="button shoot" onClick={handleShoot1} ><CameraIcon sx={{fontSize: 30}}/>Shoot</button>}
          {!shootStyle1 ? <div className="between2"></div> : ''}
          {!shootCheck1 ? <button className="buttonY" onClick={handlePrevious}><ArrowBackIosNewIcon />Previous</button> : <button className="buttonY" style={{backgroundColor: 'gray'}}><ArrowBackIosNewIcon />Previous</button>}{shootCheck1? <button className="buttonY space" onClick={handleNext}>Next <ArrowForwardIosIcon/></button>: <button className="buttonY space" style={{backgroundColor: 'gray'}}>Next <ArrowForwardIosIcon/></button>}
        </div>
    </div>  : steps == 4 ?
    <div className="containerf correctionCamera">
      <Card sx={{ maxWidth: 1675, minHeight: 1275, minWidth: 1675 , marginLeft: 35, marginTop: 5}}>
              <CardContent>
                {
                  cameraStatus5 == 'on' ?
                  <div className="online">
                  <div className="flex">
                    <h3 className="cameraTitle">Interior camera Online</h3>
                    {/*<input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                       // component="span"
                        >
                        <CameraIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleShoot('4')} color="primary" />
                        </IconButton>
                    </label>*/}
                  </div>
                </div> :
                  <div className="offline">
                  <div className="flex">
                    <h3 className="cameraTitle">Interior camera Offline</h3>
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        //component="span"
                        >
                            <CableIcon sx={{fontSize: 30, marginLeft: 70}} onClick={e => handleConnection('4')} color="primary" />
                        </IconButton>

                    </label>
                  </div>
                </div>
                }
                {/*cameraFour ?*/ damage31 == 1 ? /*<img src={cameraFour} style={{maxWidth: '800px', maxHeight: '850px', marginLeft: '20px'}}  alt={"snap"} className={classes.img}></img> */
                <LiveInteriorViewer link={cameraFour} camera={5}/>
                : damage31 == 2?
                  <div>
                    <div className="selectDamage">
                    <h4 className="titleDamage">Damage 1</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[64] = e.value;
                                      setDamageType(placeHld)
                              
                                    }}
                                    value={{label: damageType[64] || 'None', value: damageType[64] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[64] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 64)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 2</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[65] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[65] || 'None', value: damageType[65] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[65] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 65)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 3</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[66] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[66] || 'None', value: damageType[66] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[66] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 66)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 4</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[67] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[67] || 'None', value: damageType[67] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[67] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 67)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 5</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[68] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[68] || 'None', value: damageType[68] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[68] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 68)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 6</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[69] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[69] || 'None', value: damageType[69] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[69] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 69)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 7</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[70] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[70] || 'None', value: damageType[70] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[70] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 70)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 8</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[71] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[71] || 'None', value: damageType[71] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[71] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 71)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 9</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[72] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[72] || 'None', value: damageType[72] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[72] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 72)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                        <h4 className="titleDamage">Damage 10</h4>
                        <div className="flexAssets">
                              <div className="inlineLnI">
                                      <Select 
                                    options={optionsDamage}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => {
                                      const placeHld = [...damageType];
                                      placeHld[73] = e.value;
                                      setDamageType(placeHld)
                                      console.log(files)
                                    }}
                                    value={{label: damageType[73] || 'None', value: damageType[73] || ''}}
                                />
                              </div>
                              <div className="inlineLnI">
                              {damageType[73] !== 'Missing Parts'?
                              <div id={'element'}>
                                <Select 
                                    options={optionsNopa}
                                    styles={colourStylesS}
                                    isSearchable={ false }
                                    onChange={(e) => handleDamageDimention(e, 73)}
                                    //value={this.state.valueClass}
                                  />
                                </div>: ''}
                              </div>
                        </div>
                  </div>
                </div>:
                <div>
                  <h3 className="title">Comments on damages</h3>
                  <textarea rows={15} style={{minWidth: '100%'}} placeholder="...comments" onChange={(e) =>{
                    const placeHold = [...comments];
                    placeHold[8] = e.target.value;
                    setComments(placeHold);
                  }}></textarea>
                </div> 
                /*: <CircularProgress />*/}
              </CardContent>
            <CardActions>
            {damage31 == 1? <button className="button" onClick={handleDamage31}>Mark Damage</button>: damage31 == 2? <><button className="button" onClick={handleDamage311}>Ok</button><button className="button" onClick={handleDamage31}>Comments</button></> :<button className="button" style={{marginTop: '15%'}} onClick={handleDamage3111}>Ok</button>}

            </CardActions>
          </Card>
          <div className="flex1">
        {countShoots == 10 || subStuck || interiorTimer == true ? <div className="flex"><button className="button shoot" /*onClick={handleShootInterior}*/ style={{backgroundColor:'gray'}}><CameraIcon sx={{fontSize: 30}}/>Shoot</button><h4 className="countShoots">Photo taken: {countShoots}</h4></div> :<div className="flex"><button className="button shoot" onClick={handleShootInterior} ><CameraIcon sx={{fontSize: 30}}/>Shoot</button><h4 className="countShoots">Photo taken: {countShoots}</h4></div>}
          <div className="between1"></div>
          {submitProgress == 3 ? <div className="waitEle"><CircularProgress /><h5 className="wait">Please Wait</h5></div> : submitProgress == 1 ? <div className="waitEle spaceUp"><h5 className="wait">Data saved and file created</h5></div> : submitProgress == 2 ? <>Error: Please submit again</>:''}
          {countShoots == 0  ? <button className="buttonX" onClick={handlePrevious}><ArrowBackIosNewIcon/> Previous</button>: <button className="buttonX" style={{backgroundColor: 'gray'}}><ArrowBackIosNewIcon/> Previous</button>}{subStuck ?<button className="buttonX space" style={{backgroundColor: 'gray'}}>Sign <DrawIcon /></button> : <button className="buttonX space" onClick={handleClickOpen} >Sign <DrawIcon /></button>}{submitProgress == 1 || !stuck ?<button className="buttonX space" style={{backgroundColor: 'gray'}} >Submit <PublishIcon/></button>: <button className="buttonX space" onClick={handleSubmitCameras} >Submit <PublishIcon/></button>}
        </div>
        {submitProgress == 1? <div className="wide"><h3 className="refresh">Refresh for another take!!</h3></div> : ''}
    </div> : ''
    }


   {/* <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Upload Pictures
      </Button>
      <Button variant="outlined" onClick={handleSubmit}>
        Submit Test
      </Button>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <div className="pictures">
                {source &&
                        display}
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                    />
                    <label htmlFor="icon-button-file">
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        >
                        <CameraIcon sx={{fontSize: 80}} color="primary" />
                        </IconButton>
                    </label>
               
                </div>
            </Grid>
        </Grid>
        </div>                          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="buttonPictures" onClick={handleClose}>Close</button>
        </DialogActions>
      </Dialog>
    </React.Fragment>*/}
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <div className="pictures">
                  <h3>Declaration</h3>
                  <p>The report has been completed on {new Date().getDate().toString().padStart(2, "0")+'-'+(new Date().getMonth()+1).toString().padStart(2, "0")+'-'+new Date().getFullYear()} and meets the
requirements as set out our terms & conditions. The damages shown
in the photos provided represent a true reflection of the condition of
the vehicle as seen by myself on behalf of Tambor Ltd.</p>
                  <SignatureCanvas 
                    penColor='black'
                    canvasProps={{width: 900, height: 500, className: 'sigCanvas'}}
                    ref={sigCanvas}
                    />
                      {/*<button onClick={save}>Save</button>*/}
                      <br></br>
                      <div className="flexX">
                        <h3 className="titlel">Name</h3>
                        <Select 
                            options={reportOptions}
                            styles={colourStylesSS}
                            isSearchable={ false }
                            onChange={(e) => setFleetGuy(e.value)}
                            //value={this.state.valueClass}
                        />
                        <div className="desp"></div>
                        <button className='buttonS' onClick={clear}>Clear</button>
                      </div>
                </div>          
            </Grid>
        </Grid>
        </div>                          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="buttonS" onClick={save}>Save & Close</button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    </div>
  );
        
}
export default ConditionReportForm; 