import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react'; 
import ReactPDF from '@react-pdf/renderer';

const PDFViewer = (props) => {
   const [render, setRender] =useState();
   
   useEffect(()=>{
      axios.post("https://node-skso.co.uk/getPdf" ,{link: props.link},{responseType: 'arraybuffer'}).then(response => {
         
      let blob = new Blob([response.data], { type: "application/pdf" }); 
      console.log(response)
      
      
    

      setRender(blob) 
      
      }).catch(err => console.log(err.message))
   }, [props.link])
   let url
   if(render){
      url = URL.createObjectURL(render)
   }
  
 return (

 <div className='pdfview'>
    <iframe src={url} className='pdfview' />
    {/*<ReactPDF
      file={{
         data: render
      }}
   />*/}
   
 </div>
 );
};
export default PDFViewer;